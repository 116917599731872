import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "location-select-container" }
const _hoisted_2 = { class: "location-selector" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "display" }
const _hoisted_5 = {
  key: 0,
  class: "options-container"
}
const _hoisted_6 = ["onClick"]
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "display" }
const _hoisted_9 = {
  key: 0,
  class: "field-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass(["select-container", {focused: $setup.focused}]),
        tabindex: "0",
        onFocus: $setup.handleFocus,
        onBlur: $setup.handleBlur,
        ref: "container"
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(["option current", {unselected: !$setup.modelValue}])
        }, [
          ($setup.modelValue && $setup.locationTypeData[$setup.modelValue.type].icon)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                class: "icon",
                src: $setup.locationTypeData[$setup.modelValue.type].icon
              }, null, 8 /* PROPS */, _hoisted_3))
            : _createCommentVNode("v-if", true),
          _createElementVNode("span", _hoisted_4, _toDisplayString($setup.modelValue ? $setup.locationTypeData[$setup.modelValue.type].display : "Select Location"), 1 /* TEXT */)
        ], 2 /* CLASS */),
        _createVNode(_Transition, { name: "grow-in" }, {
          default: _withCtx(() => [
            ($setup.focused)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.arrayLocationTypes, (location) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: "option",
                      onClick: ($event: any) => ($setup.selectOption(location))
                    }, [
                      ($setup.locationTypeData[location].icon)
                        ? (_openBlock(), _createElementBlock("img", {
                            key: 0,
                            class: "icon",
                            src: $setup.locationTypeData[location].icon
                          }, null, 8 /* PROPS */, _hoisted_7))
                        : _createCommentVNode("v-if", true),
                      _createElementVNode("span", _hoisted_8, _toDisplayString($setup.locationTypeData[location].display), 1 /* TEXT */)
                    ], 8 /* PROPS */, _hoisted_6))
                  }), 256 /* UNKEYED_FRAGMENT */))
                ]))
              : _createCommentVNode("v-if", true)
          ]),
          _: 1 /* STABLE */
        })
      ], 34 /* CLASS, HYDRATE_EVENTS */)
    ]),
    ($setup.modelValue)
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.locationTypeData[$setup.modelValue.type].fields, (field) => {
            return (_openBlock(), _createElementBlock(_Fragment, null, [
              (field.fieldSize == 'fullwidth')
                ? (_openBlock(), _createBlock($setup["TextField"], {
                    key: 0,
                    class: "input-field",
                    label: field.display,
                    modelValue: $setup.modelValue.fields[field.dataName],
                    "onUpdate:modelValue": ($event: any) => (($setup.modelValue.fields[field.dataName]) = $event)
                  }, null, 8 /* PROPS */, ["label", "modelValue", "onUpdate:modelValue"]))
                : _createCommentVNode("v-if", true),
              (field.fieldSize == 'halfwidth')
                ? (_openBlock(), _createBlock($setup["TextField"], {
                    key: 1,
                    class: "input-field half-width",
                    label: field.display,
                    modelValue: $setup.modelValue.fields[field.dataName],
                    "onUpdate:modelValue": ($event: any) => (($setup.modelValue.fields[field.dataName]) = $event)
                  }, null, 8 /* PROPS */, ["label", "modelValue", "onUpdate:modelValue"]))
                : _createCommentVNode("v-if", true),
              (field.fieldSize == 'fullheight')
                ? (_openBlock(), _createBlock($setup["TextArea"], {
                    key: 2,
                    class: "input-field full-height",
                    label: field.display,
                    modelValue: $setup.modelValue.fields[field.dataName],
                    "onUpdate:modelValue": ($event: any) => (($setup.modelValue.fields[field.dataName]) = $event)
                  }, null, 8 /* PROPS */, ["label", "modelValue", "onUpdate:modelValue"]))
                : _createCommentVNode("v-if", true)
            ], 64 /* STABLE_FRAGMENT */))
          }), 256 /* UNKEYED_FRAGMENT */))
        ]))
      : _createCommentVNode("v-if", true)
  ]))
}