import { createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, TransitionGroup as _TransitionGroup, withCtx as _withCtx, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-48b43bbe"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "loader" }
const _hoisted_2 = {
  key: 0,
  class: "loader-text"
}
const _hoisted_3 = {
  key: 0,
  class: "loading-tip-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["loader-wrapper", $setup.cssClasses])
  }, [
    _createElementVNode("section", null, [
      _createElementVNode("div", _hoisted_1, [
        _createVNode($setup["LoaderDotDotDot"])
      ]),
      ($props.message && $props.message !== '')
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString($props.message), 1 /* TEXT */))
        : _createCommentVNode("v-if", true)
    ]),
    ($props.fullScreen)
      ? (_openBlock(), _createElementBlock("section", _hoisted_3, [
          _createVNode(_TransitionGroup, { name: "loading" }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.tip, (t) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "loader-text tip",
                  key: t
                }, _toDisplayString(t), 1 /* TEXT */))
              }), 128 /* KEYED_FRAGMENT */))
            ]),
            _: 1 /* STABLE */
          })
        ]))
      : _createCommentVNode("v-if", true)
  ], 2 /* CLASS */))
}