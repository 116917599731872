export default [
    'Help us improve your experience by giving us your feedback',
    'Opt creates a standard link for you to share with the public through your email signature, website, social media and more.',
    'You can create unlimited unique links for specific meeting types or people.',
    'Make and offer by deciding how much you would pay or charge for 15, 30, 60 minutes.',
    'Opt generates a link you can directly share in an email or text communicating respect and avoiding the awkward conversation around paying for time.',
    'Opt Syncs with your Google or Outlook calendar, allowing the other party to schedule without the back-and-forth. When both sides choose to put a value ON time, meeting are better.',
    "With Opt as your trusted third party you don't have to worry about chasing down money or sending invoices hoping they'll get paid.",
    "Connecting your calendar will allow you to easily let people know when you're available and when you're busy.",
    "When your personal calendar is connected, Opt can book meetings and block out times you are unavailable.",
    "You look great!",
    "Opt has made it easy to value your time and ensure that others know you value their time as well.",
    "A personal message can go a long way!",

]