import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "new-invite-container" }
const _hoisted_2 = {
  key: 0,
  class: "page"
}
const _hoisted_3 = {
  key: 0,
  class: "page"
}
const _hoisted_4 = {
  key: 0,
  class: "page"
}
const _hoisted_5 = {
  key: 0,
  class: "page"
}
const _hoisted_6 = {
  key: 0,
  class: "page"
}
const _hoisted_7 = {
  key: 0,
  class: "page"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_Transition, {
      onBeforeEnter: _cache[3] || (_cache[3] = ($event: any) => ($setup.noScroll = true)),
      onAfterLeave: _cache[4] || (_cache[4] = ($event: any) => ($setup.noScroll = false)),
      appear: ""
    }, {
      default: _withCtx(() => [
        ($setup.stage === $setup.InviteStage.NAME)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode($setup["InviteName"], {
                invite: $setup.invite,
                "onUpdate:invite": _cache[0] || (_cache[0] = ($event: any) => (($setup.invite) = $event)),
                onPrevious: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$router.back())),
                onProgress: _cache[2] || (_cache[2] = ($event: any) => ($setup.stage = $setup.InviteStage.RATE))
              }, null, 8 /* PROPS */, ["invite"])
            ]))
          : _createCommentVNode("v-if", true)
      ]),
      _: 1 /* STABLE */
    }),
    _createVNode(_Transition, {
      onBeforeEnter: _cache[8] || (_cache[8] = ($event: any) => ($setup.noScroll = true)),
      onAfterLeave: _cache[9] || (_cache[9] = ($event: any) => ($setup.noScroll = false)),
      appear: ""
    }, {
      default: _withCtx(() => [
        ($setup.stage === $setup.InviteStage.RATE)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode($setup["InviteRate"], {
                invite: $setup.invite,
                "onUpdate:invite": _cache[5] || (_cache[5] = ($event: any) => (($setup.invite) = $event)),
                onPrevious: _cache[6] || (_cache[6] = ($event: any) => ($setup.stage = $setup.InviteStage.NAME)),
                onProgress: _cache[7] || (_cache[7] = ($event: any) => ($setup.stage = $setup.InviteStage.NOTE))
              }, null, 8 /* PROPS */, ["invite"])
            ]))
          : _createCommentVNode("v-if", true)
      ]),
      _: 1 /* STABLE */
    }),
    _createVNode(_Transition, {
      onBeforeEnter: _cache[13] || (_cache[13] = ($event: any) => ($setup.noScroll = true)),
      onAfterLeave: _cache[14] || (_cache[14] = ($event: any) => ($setup.noScroll = false)),
      appear: ""
    }, {
      default: _withCtx(() => [
        ($setup.stage === $setup.InviteStage.NOTE)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createVNode($setup["InviteNote"], {
                invite: $setup.invite,
                "onUpdate:invite": _cache[10] || (_cache[10] = ($event: any) => (($setup.invite) = $event)),
                onPrevious: _cache[11] || (_cache[11] = ($event: any) => ($setup.stage = $setup.InviteStage.RATE)),
                onProgress: _cache[12] || (_cache[12] = ($event: any) => ($setup.stage = $setup.InviteStage.LOCATION))
              }, null, 8 /* PROPS */, ["invite"])
            ]))
          : _createCommentVNode("v-if", true)
      ]),
      _: 1 /* STABLE */
    }),
    _createVNode(_Transition, {
      onBeforeEnter: _cache[18] || (_cache[18] = ($event: any) => ($setup.noScroll = true)),
      onAfterLeave: _cache[19] || (_cache[19] = ($event: any) => ($setup.noScroll = false)),
      appear: ""
    }, {
      default: _withCtx(() => [
        ($setup.stage === $setup.InviteStage.LOCATION)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createVNode($setup["InviteLocation"], {
                invite: $setup.invite,
                "onUpdate:invite": _cache[15] || (_cache[15] = ($event: any) => (($setup.invite) = $event)),
                onPrevious: _cache[16] || (_cache[16] = ($event: any) => ($setup.stage = $setup.InviteStage.NOTE)),
                onProgress: _cache[17] || (_cache[17] = ($event: any) => ($setup.stage = $setup.InviteStage.CONTACT))
              }, null, 8 /* PROPS */, ["invite"])
            ]))
          : _createCommentVNode("v-if", true)
      ]),
      _: 1 /* STABLE */
    }),
    _createVNode(_Transition, {
      onBeforeEnter: _cache[23] || (_cache[23] = ($event: any) => ($setup.noScroll = true)),
      onAfterLeave: _cache[24] || (_cache[24] = ($event: any) => ($setup.noScroll = false)),
      appear: ""
    }, {
      default: _withCtx(() => [
        ($setup.stage === $setup.InviteStage.CONTACT)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createVNode($setup["InviteContact"], {
                invite: $setup.invite,
                "onUpdate:invite": _cache[20] || (_cache[20] = ($event: any) => (($setup.invite) = $event)),
                onPrevious: _cache[21] || (_cache[21] = ($event: any) => ($setup.stage = $setup.InviteStage.LOCATION)),
                onProgress: _cache[22] || (_cache[22] = ($event: any) => ($setup.stage = $setup.InviteStage.CREATE))
              }, null, 8 /* PROPS */, ["invite"])
            ]))
          : _createCommentVNode("v-if", true)
      ]),
      _: 1 /* STABLE */
    }),
    _createVNode(_Transition, {
      onBeforeEnter: _cache[27] || (_cache[27] = ($event: any) => ($setup.noScroll = true)),
      onAfterLeave: _cache[28] || (_cache[28] = ($event: any) => ($setup.noScroll = false)),
      appear: ""
    }, {
      default: _withCtx(() => [
        ($setup.stage === $setup.InviteStage.CREATE)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createVNode($setup["InviteCreate"], {
                instance: $setup.inviteInstance,
                "onUpdate:instance": _cache[25] || (_cache[25] = ($event: any) => (($setup.inviteInstance) = $event)),
                error: $setup.error,
                onCreateInstance: $setup.publishInvite,
                onRestart: $setup.restart,
                onProgress: _cache[26] || (_cache[26] = ($event: any) => ($setup.goTo('/links?showToast=true')))
              }, null, 8 /* PROPS */, ["instance", "error"])
            ]))
          : _createCommentVNode("v-if", true)
      ]),
      _: 1 /* STABLE */
    }),
    _createVNode($setup["Toast"], {
      time: 5000,
      showToast: $setup.showToast,
      message: $setup.inviteMessage,
      onCloseToast: _cache[29] || (_cache[29] = ($event: any) => ($setup.showToast = false))
    }, null, 8 /* PROPS */, ["showToast", "message"])
  ]))
}