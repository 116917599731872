import { createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "tester" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: "link",
        onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.popupActive = true))
      }, "Click Here to see the popup")
    ]),
    ($setup.popupActive)
      ? (_openBlock(), _createBlock($setup["PopupVideo"], {
          key: 0,
          videoLink: "intro",
          onClose: _cache[1] || (_cache[1] = ($event: any) => ($setup.popupActive = false))
        }))
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}