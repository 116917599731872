import { createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "loader-container flex-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    ($setup.loading)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["check-in-container", {'no-scroll': $setup.noScroll}])
        }, [
          _createElementVNode("div", _hoisted_1, [
            _createVNode($setup["Loader"])
          ])
        ], 2 /* CLASS */))
      : _createCommentVNode("v-if", true),
    _createVNode($setup["CheckInPopup"], {
      user: $setup.user,
      selectedMeeting: $setup.meeting,
      onClosePopup: $setup.routeBack,
      onDeepClosePopup: _cache[0] || (_cache[0] = ($event: any) => ($setup.routeTo('')))
    }, null, 8 /* PROPS */, ["user", "selectedMeeting"])
  ], 64 /* STABLE_FRAGMENT */))
}