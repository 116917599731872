enum EnvironmentOptions {
    Dev  = "development",
    Prod = "production",
    Pre  = "preview",
    Test = "test",
}

const devUrls = [
    "0.0.0.0",
    "localhost",
    ":8080",
    ":8081",
];

let isDevEnv = (window: Window) => devUrls.some((x) => window.location.href.includes(x));

/**
 * Decides environment state based on url
 */
let currentEnvironment = (window: Window) => {
    isDevEnv(window)
        ? EnvironmentOptions.Dev
        : EnvironmentOptions.Prod;
}

export {isDevEnv, currentEnvironment};