import { vModelText as _vModelText, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "custom-text-area" }
const _hoisted_2 = ["rows", "cols", "readonly", "disabled", "placeholder"]
const _hoisted_3 = { class: "input-label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("textarea", {
      class: _normalizeClass(["input", { noBorder: $props.noBorder, addFocus: $props.readOnly }]),
      rows: $setup.rows,
      cols: $setup.cols,
      maxlength: 495,
      readonly: $props.readOnly,
      required: "",
      disabled: $props.disabled,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($props.modelValue) = $event)),
      placeholder: $props.placeholderText,
      onKeyup: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:modelValue', $props.modelValue)))
    }, null, 42 /* CLASS, PROPS, HYDRATE_EVENTS */, _hoisted_2), [
      [_vModelText, $props.modelValue]
    ]),
    _createElementVNode("label", _hoisted_3, _toDisplayString($props.label), 1 /* TEXT */)
  ]))
}