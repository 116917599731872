import tipCopy from "./tip-copy"

const TIP_INDEX_KEY = 'loading.tipIndex'

export function getNextLoadingTip() {
    const lastTip = Number(sessionStorage.getItem(TIP_INDEX_KEY) || Math.floor(Math.random() * tipCopy.length));
    const nextTip = (lastTip + 1) % tipCopy.length;
    sessionStorage.setItem(TIP_INDEX_KEY, nextTip.toString());
    return tipCopy[nextTip];
}

export default getNextLoadingTip