import { ISOTimeChunk, MomentTimeChunk } from './meeting-request-types';
import Moment from "moment";

export function convertMomentToISOTimestamp( timestamp: MomentTimeChunk ): ISOTimeChunk {
    return {
        startTime: timestamp.startTime.toISOString(),
        endTime: timestamp.endTime.toISOString()
    };
}

export function convertISOTimestampToMoment( timestamp: ISOTimeChunk ): MomentTimeChunk {
    return {
        startTime: Moment(timestamp.startTime),
        endTime: Moment(timestamp.endTime)
    };
}