import "core-js/stable";
import "regenerator-runtime/runtime";

import "@/stylesheets/app.scss";

import events from "./events";
import {initEvents} from "@busy-human/vue-component-library/util/events";
initEvents(events);

import { createPinia } from "pinia";
const pinia = createPinia();

import router from "./router";
import app from "./app";

import modal from "@busy-human/vue-component-library/components/modal.vue";

import {getUserId, initializeAuth} from './util/auth'
import { Globals } from "@busy-human/gearbox";
import { useUserspace } from "./store/userspace";


initializeAuth().then(async () => {
    const uid = getUserId();

    app.use(pinia);
    
    const userspace = useUserspace(pinia);
    if(uid)
        await userspace.setUserId(uid);

    app.use(router);
    app.component("v-modal", modal);
    app.mount("#application-root");
})
