import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "calendar" }
const _hoisted_2 = { class: "calendar-header" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "title" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "dates-container" }
const _hoisted_7 = { class: "day" }
const _hoisted_8 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", {
        class: "left-chevy",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('deincrement')))
      }, [
        _createElementVNode("img", { src: $setup.LeftChevy }, null, 8 /* PROPS */, _hoisted_3)
      ]),
      _createElementVNode("span", _hoisted_4, _toDisplayString($setup.Months[$props.month]), 1 /* TEXT */),
      _createElementVNode("span", {
        class: "right-chevy",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('increment')))
      }, [
        _createElementVNode("img", { src: $setup.RightChevy }, null, 8 /* PROPS */, _hoisted_5)
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      (_openBlock(), _createElementBlock(_Fragment, null, _renderList($setup.Days, (day) => {
        return _createElementVNode("div", _hoisted_7, _toDisplayString(day), 1 /* TEXT */)
      }), 64 /* STABLE_FRAGMENT */)),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.daysInMonth($props.year, $props.month), (date) => {
        return (_openBlock(), _createElementBlock("div", {
          class: _normalizeClass(["date", {'selected': $props.year == $props.modelValue.getFullYear() && $props.month == $props.modelValue.getMonth() && date == $props.modelValue.getDate(), 'disabled': !($props.availability[date - 1] && $props.availability[date - 1].length > 0)}]),
          style: _normalizeStyle({'--column': $setup.dayOfWeek($props.year, $props.month, date) + 1}),
          onClick: ($event: any) => (_ctx.$emit('update:modelValue', new Date($props.year, $props.month, date)))
        }, _toDisplayString(date), 15 /* TEXT, CLASS, STYLE, PROPS */, _hoisted_8))
      }), 256 /* UNKEYED_FRAGMENT */))
    ])
  ]))
}