import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "temp-cal-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($setup.loading)
    ? (_openBlock(), _createBlock($setup["Loader"], { key: 0 }))
    : (_openBlock(), _createElementBlock("section", _hoisted_1, [
        _createElementVNode("div", null, _toDisplayString($setup.selectedDate.toLocaleString()), 1 /* TEXT */),
        _createVNode($setup["Calendar"], {
          modelValue: $setup.selectedDate,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.selectedDate) = $event)),
          month: $setup.currentMonth,
          year: $setup.currentYear,
          availability: $setup.slotsForMonth,
          onIncrement: _cache[1] || (_cache[1] = ($event: any) => ($setup.adjustMonth())),
          onDeincrement: _cache[2] || (_cache[2] = ($event: any) => ($setup.adjustMonth(-1)))
        }, null, 8 /* PROPS */, ["modelValue", "month", "year", "availability"]),
        _createVNode($setup["TimeScroller"], {
          modelValue: $setup.selectedDate,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.selectedDate) = $event)),
          availability: $setup.slotsForMonth
        }, null, 8 /* PROPS */, ["modelValue", "availability"])
      ]))
}