import { useUserspace } from "@/store/userspace";
import { CollectionEntityInterface } from "@busy-human/gearbox";
import { Calendars, Userspaces } from "@busy-human/opt-library";
import { auth, functions, httpsCallable } from './firebase';
import { beginMicrosoftSetup } from "./functions";


const CLIENT_ID = "cccaaeca-541a-4902-88a9-72da17768535";
const SCOPE = "offline_access https://graph.microsoft.com/Calendars.ReadWrite";
const REDIRECT_URI = "https://us-central1-leanmeet-optme.cloudfunctions.net/finishMicrosoftSetup";
const SCOPES = ["offline_access", "https://graph.microsoft.com/Calendars.ReadWrite"];


const TOKEN_LENGTH = 32;

function makeID(length: number) {
    const vals: string[] = [];
    const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for(let i = 0; i < length; i++) {
        const x = Math.floor(Math.random() * chars.length);
        vals.push(chars[x]);
    }
    return vals.join('');
} 

const calendarApi = {
    linkMicrosoft: async (popupInstance: Window | null) => {        
        const Cals: CollectionEntityInterface<Calendars.OptCalendar>
            = Userspaces.Collection.subCollectionEntity(useUserspace().uid, 'calendars');

        // const token = makeID(TOKEN_LENGTH);

        
        const calRef = Cals.docEntity('microsoft');
        // await calRef.set({'type': 'microsoft', refreshToken: '', stateToken: token, accessToken: ''});

        // const state = btoa(JSON.stringify(
        //     { token, uid: auth.currentUser?.uid }
        // ))

        // const url = new URL(`https://login.microsoftonline.com/common/oauth2/v2.0/authorize`);
        // url.searchParams.set('client_id', CLIENT_ID);
        // url.searchParams.set('response_type', 'code');
        // url.searchParams.set('redirect_uri', REDIRECT_URI);
        // url.searchParams.set('scope', SCOPE);
        // url.searchParams.set('response_mode', 'query');
        // url.searchParams.set('state', state);
        // url.searchParams.set('prompt', 'consent');

        // console.log(url.toString());
        const url = (await beginMicrosoftSetup({uid: useUserspace().uid})).data;
        if(!popupInstance) return {
            success: false,
            error: 'Popup failed'
        }
        popupInstance.location.href = url;

        let resolve: () => void
        let reject: (error: any) => void
        const promise = new Promise<void>((res, rej) => {
            resolve = res;
            reject = rej;
        })

        let popupClosed = setInterval(function() {
            if(popupInstance.closed){
                clearInterval(popupClosed);
                reject("Popup closed by User")
            }
        }, 250)

        
        await calRef.fetch();
        calRef.onUpdate(event => {
            if(calRef.data().refreshToken !== '') resolve();
            if(typeof calRef.data().error !== 'undefined') reject(calRef.data().error);
        });
        calRef.listen();
        let timeout = setTimeout(() => reject("Request timed out"), 5 * 60 * 1000);

        let success = false;
        let error: any = null;

        try {
            await promise;
            const data = calRef.data();
            success = true;
        } catch (e) {
            console.error(e);
            error = e;
            await calendarApi.unlinkMicrosoft();
        } finally {
            calRef.cleanup();
            clearTimeout(timeout);
        }

        return {success, error};
    },
    unlinkMicrosoft: async () => {
        const unlinkMicrosoft = httpsCallable<void | { uid?: string }, any>(functions, 'unlinkMicrosoft');
        const res = await unlinkMicrosoft({ uid: useUserspace().uid });
        console.log("Unlinked Microsoft:", res);
    }
}

export default calendarApi;
// const msalConfig = {
//     auth: {
//         clientId: '01285905-9405-4208-bf8f-f8774839cc6f',
//         // comment out if you use a multi-tenant AAD app
//         authority: 'https://login.microsoftonline.com/f8cdef31-a31e-4b4a-93e4-5f571e91255a',
//         redirectUri: 'http://localhost:8080'
//     }
// }

// const msalRequest = { scopes: [] };
// function ensureScope (scope) {
//     if (!msalRequest.scopes.some((s) => s.toLowerCase() === scope.toLowerCase())) {
//         msalRequest.scopes.push(scope);
//     }
// }
// //Initialize MSAL client
// const msalClient = new msal.PublicClientApplication(msalConfig);

// // Log the user in
// async function signIn() {
//     const authResult = await msalClient.loginPopup(msalRequest);
//     sessionStorage.setItem('msalAccount', authResult.account.username);
// }
// //Get token from Graph
// async function getToken() {
//     let account = sessionStorage.getItem('msalAccount');
//     if (!account) {
//         throw new Error(
//             'User info cleared from session. Please sign out and sign in again.');
//     }
//     try {
//         // First, attempt to get the token silently
//         const silentRequest = {
//             scopes: msalRequest.scopes,
//             account: msalClient.getAccountByUsername(account)
//         };

//         const silentResult = await msalClient.acquireTokenSilent(silentRequest);
//         return silentResult.accessToken;
//     } catch (silentError) {
//         // If silent requests fails with InteractionRequiredAuthError,
//         // attempt to get the token interactively
//         if (silentError instanceof msal.InteractionRequiredAuthError) {
//             const interactiveResult = await msalClient.acquireTokenPopup(msalRequest);
//             return interactiveResult.accessToken;
//         } else {
//             throw silentError;
//         }
//     }
// }


// // Create an authentication provider
// const authProvider = {
//     getAccessToken: async () => {
//         // Call getToken in auth.js
//         return await getToken();
//     }
// };
// // Initialize the Graph client
// const graphClient = MicrosoftGraph.Client.initWithMiddleware({ authProvider });
// //Get user info from Graph
// async function getUser() {
//     ensureScope('user.read');
//     return await graphClient
//         .api('/me')
//         .select('id,displayName')
//         .get();
// }

// async function microsoftSignIn() {    
//     await signIn();

//     const user = await getUser();

//     console.log("Microsoft User", user)
// }