import { createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "video-frame" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($props.videoLink)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "popup-video",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close')))
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("video", {
            class: "video",
            onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"])),
            controls: "controls",
            name: "Opt Information",
            autoplay: ""
          }, [
            _createElementVNode("source", {
              src: $setup.videos[$props.videoLink],
              type: "video/mp4"
            }, null, 8 /* PROPS */, _hoisted_2)
          ])
        ])
      ]))
    : _createCommentVNode("v-if", true)
}