import moment, {Moment} from "moment";
import {DayWithMomentChunk, ISOTimeChunk, MomentTimeChunk, RequestStage, TimeTuple, DayOfWeek, DailyAvailability, DefaultDailyAvailability} from "./meeting-request-types";
import {computed, ref, onMounted, onUnmounted, Ref, WritableComputedRef, ComputedRef} from 'vue'
import { DataInstance, InviteLinks, Meetings, Userspaces, Weekday } from '@busy-human/opt-library';
import { emitter, EventType } from "./meeting-event-emitter";
import { compileScript } from "@vue/compiler-sfc";
import { AvailabilityPopulator } from "./availability-populator";
import { convertISOTimestampToMoment } from "./schedule-util";
import { httpsCallable } from 'firebase/functions';
import {functions} from '../../util/firebase'
import { upperFirst } from "lodash";
import { collection, getDoc, doc, getDocs, getDocsFromCache, getDocsFromServer, addDoc, deleteDoc, onSnapshot, updateDoc } from "firebase/firestore"
import { db } from "@/util/firebase"

const getUserAvailability = httpsCallable<{uid: string, year: number, month: number}, {startTime: string, endTime: string}[]>(functions, 'getUserAvailability')

export interface useMeetingRequestScheduleOptions {
    request: Ref<Meetings.Model>, 
    meetingLength: Ref<number>,
    env: Ref<string>
}

let hasInitialized = false;
export interface useMeetingRequestScheduleMixinObject {
    senderUid: Ref<string|null>,
    meetingDate: WritableComputedRef<Date | undefined>,
    meetingLength: Ref<number>,
    isDateSelected: ComputedRef<boolean>,
    loadedDays: Ref<DayWithMomentChunk[][]>,
    earliestDayLoaded: Ref<boolean>,
    getPreviousDay: Function,
    getNextDay: Function,
    setEnd: Function,
    setStart: Function,
    formatDate: Function,
    killMixin: Function,
    disabledDays: Ref<string[]>,
    blockedDays: Ref<string[]>,
    events: Ref<ISOTimeChunk[]>,
    checkPreviousDay: Function,
    updateUserAvailability: Function
}
let mixinResult: useMeetingRequestScheduleMixinObject;

export function useMeetingRequestSchedule(options: useMeetingRequestScheduleOptions | null): useMeetingRequestScheduleMixinObject {
    // console.log("initialized",hasInitialized);
    // console.log("options:",options)
    // console.log("MIXIN result:",mixinResult)
    if(!hasInitialized) {
        if(!options) {
            throw new Error(`useMeetingRequestSchedule must be called with options the first time`);
        }
        initializeMeetingRequestScheduleMixin(options);
    }

    return mixinResult;
}


function initializeMeetingRequestScheduleMixin({ request, meetingLength, env }: useMeetingRequestScheduleOptions) {
    const senderUid = ref<string|null>(null);
    const timesAvailableForDay = ref<DayWithMomentChunk[]>([]);
    const timesAvailable = ref<DayWithMomentChunk[]>([]);
    const calledPrevFlag = ref<boolean>(false);
    const minDay = ref<Date>()
    const maxDay = ref<Date>()
    const blockedDays = ref<string[]>([])
    const disabledDays = ref<string[]>([])
    const days = ['sunday','monday','tuesday','wednesday','thursday','friday','saturday']
    const startTime = ref<Moment>()
    const endTime = ref<Moment>()
    const events = ref<ISOTimeChunk[]>([])
    const loadedDays = ref<DayWithMomentChunk[][]>([])
    const earlistDayLoaded = ref<boolean>(false)
    const scrollFirstLoad = ref<boolean>(true);
    const isDateSelected = computed(() => !!(meetingDate?.value));
    const startTimeTmp = ref<Moment>()
    const endTimeTmp = ref<Moment>()


    const getDailyAvailability = async() => {
        if(!senderUid.value) {
            throw new Error("senderUid is required");
        }
        
        // TODO: Determine ideal type for bounds
        // Translate bounds
        var availability : DailyAvailability = DefaultDailyAvailability();

        const userRef = doc(db, "Userspaces", senderUid.value)
        const docSnap =  await getDoc(userRef)
        if(docSnap.exists()){
            const tmpData = docSnap.data() as Userspaces.Model;
            let timesAvailable = Array.isArray(tmpData.timesAvailable) ? tmpData.timesAvailable[0] : tmpData.timesAvailable;

            if(!timesAvailable) {
                console.warn("Unable to resolve user's timesAvailable, using defaults");
            } else {
                availability.startTime = [timesAvailable.startTime, 0];
                availability.endTime = [timesAvailable.endTime, 0];
                availability.availableDays = [...timesAvailable.days];
            }
            

        }else{
            console.log("Document doesn't exist.")
        }   
        
        return availability;
    }


    let lastUpdatedUserAvailability : (Moment | null) = null;
    const updateUserAvailability = async (uid?: string) => {
        const now = moment();
        const month = now.month();
        const year = now.year();

        if(uid) {
            senderUid.value = uid;
        }

        if(!senderUid.value) {
            throw new Error("senderUid is required");
        }

        // Up to once per minute, get the latest calendar data
        if(!lastUpdatedUserAvailability || now.clone().subtract(1, "minutes").isAfter(lastUpdatedUserAvailability)) {
            const result = await getUserAvailability({
                uid: senderUid.value, year, month
            });
    
            events.value = result.data;
            lastUpdatedUserAvailability = now.clone();
            // console.log("updateUserAvailability EVENTS", events.value);
        }
      
        return events.value || [];
    }
    
    const setDisabledDays = (value:string[])=>{
        // console.log("SET DISABLED DAYS:",value)
        disabledDays.value = value
    }

    const setEvents = (value:ISOTimeChunk[])=>{
        events.value = value
    }

    const formatDate = (date:Date | string) =>{
        let tmp = moment(new Date(date)).format("MMMM DD, YYYY")
        return tmp
    }

    const bufferTime = ref<number>(0)

    emitter.on(EventType.OnDateSelected, (val) => {
        formatTimeChunks()
        // getTimeChunks()
    });

    const meetingDate = computed({
        get() {
            const date = new Date(request.value.startTime);
            if(date.valueOf()) {
                return new Date(date.setHours(0, 0, 0, 0));
            } else return undefined;
        },
        set(val) {
            if(!val) return;
            const start = new Date(request.value.startTime);
            const startMoment = start.setFullYear(val.getFullYear(), val.getMonth(), val.getDate());
            // let updatedRequest = request.value;
            let stage = checkStage()
            while(!stage && loadedDays.value.length > 0){
                loadedDays.value.pop();
            }
            // updatedRequest.startTime = new Date(startMoment).toISOString()
            request.value.startTime = new Date(startMoment).toISOString()
            // console.log("Mixin data emitted:",request.value.startTime)
            emitter.emit(EventType.OnDateSelected, start);
        }
    })

    const setStart = (value:Moment)=>{
        startTimeTmp.value = value
        startTime.value = value
    }
    const setEnd = (value:Moment)=>{
        endTime.value = value
        endTimeTmp.value = value
    }
    
    const getPreviousDay = async() =>{
        checkPreviousDay();
        let tmp = moment(minDay.value)
        tmp.subtract(1,'d')
        await checkTimeChunks(tmp)

        calledPrevFlag.value = true

        if(dayIsPast()){
            earlistDayLoaded.value = true
            return
        }

        if(dayIsBlocked(tmp)){
            let foundOpenDay = false
            while(!foundOpenDay){
                tmp.subtract(1,'d')
                await checkTimeChunks(tmp)
                if(dayIsPast()){
                    earlistDayLoaded.value = true
                    return
                }
                if(!dayIsBlocked(tmp)){
                    foundOpenDay = true;
                }
            }
        }

        request.value.startTime = tmp.toISOString();

        formatTimeChunks();

        function dayIsPast() {
            return parseInt(tmp.format("DD")) < parseInt(moment().format("DD"))
        }
    }
    const checkPreviousDay = () =>{
        let tmp = moment(minDay.value)
        tmp.subtract(1,'d')
        if(dayIsBlocked(tmp)){
            // console.log("Check previous returned false")
            return false
        }
        // console.log("Check previous returned true")
        return true
    }
    
    const getNextDay = async() =>{
        let tmp = moment(maxDay.value)
        tmp.add(1,'d')
        await checkTimeChunks(tmp)

        if(dayIsBlocked(tmp)){
            let foundOpenDay = false
            while(!foundOpenDay){
                tmp.add(1,'d')
                await checkTimeChunks(tmp)
                if(!dayIsBlocked(tmp)){
                    foundOpenDay = true;
                }
            }
        }

        request.value.startTime = tmp.toISOString();
        formatTimeChunks()
        startTime.value = startTimeTmp.value
        endTime.value = endTimeTmp.value
    
    }

  

    const dayIsBlocked = (dayMoment: Moment) => {
        let day = days[dayMoment.day()]
        let date = dayMoment.format('MMMM DD, YYYY')
        // console.log("blocked days:",blockedDays.value)
        // console.log("disabled days:",disabledDays.value)
        // console.log(blockedDays.value.includes(date),disabledDays.value.includes(day))
        return (blockedDays.value.includes(date) || disabledDays.value.includes(day))
    }
    
    /** check to see if times are in this day, if not push it to blocked days */
    const checkTimeChunks = async(dayMoment: Moment) =>{
        let date = dayMoment.format('MMMM DD, YYYY')
        const availability = await getDailyAvailability()
        const populator = new AvailabilityPopulator({stepSize: meetingLength.value});
        let startTime = dayMoment.toISOString();

        // TODO: Get actual availability
        let slots = populator.run({
            date: moment(startTime),
            availability,
            events: events.value.map(ev => convertISOTimestampToMoment(ev))
        });
        if(slots.length == 0 ){
            blockedDays.value.push(date)
        }
    }


    // const getTimeChunks = () =>{
    //     console.log(`Getting Time Chunks`,timesAvailable.value);
    //     timesAvailableForDay.value = []
    //     let date = moment(meetingDate.value).format("MMMM D, YYYY")
    //     for(let i = 0; i < timesAvailable.value.length; i++){
    //     console.log("timesAvailable", timesAvailableForDay.value[i])
    //         if(date == timesAvailable.value[i].day){
    //             timesAvailableForDay.value.push(timesAvailable.value[i])
    //         }
    //     }
    
    //     //check before to see if there are even times left in day.
    //     formatTimeChunks()
    // }

    interface ChunksFromDayWithEventsOptions {
        tmpArray: DayWithMomentChunk[], 
        endOfDayPointer: moment.Moment | undefined,
        currentTime: moment.Moment,
        date: string
    }
    
    
    const formatTimeChunks = async () =>{
        if(!request.value.startTime) {
            throw new Error("StartTime and EndTime must be specified");
        }

        const availability = await getDailyAvailability()
        const populator = new AvailabilityPopulator({stepSize: meetingLength.value});
        // TODO: Get actual availability
        let slots = populator.run({
            date: moment(request.value.startTime),
            availability,
            events: events.value.map(ev => convertISOTimestampToMoment(ev))
        });
        let date = new Date(slots[0].day)
        if(loadedDays.value.length === 0){
            minDay.value = date
            maxDay.value = date
        }
        if(loadedDays.value.length > 0){
            let dateMoment = moment(date)
            let maxMoment = moment(maxDay.value)
            let minMoment = moment(minDay.value)

            // console.log("date:",dateMoment.format('MMMM DD, YYYY'), "max:",maxMoment.format("MMMM DD, YYYY"), "min:", minMoment.format("MMMM DD, YYYY"))


            if(dateMoment.isBefore(minMoment)){
                minDay.value = date
            }

            if(dateMoment.isAfter(maxMoment)){
                maxDay.value = date
                // console.log("HIT NEW MAX:",maxDay.value)
            }

        }

        let logTimes = "";
        for(let i = 0; i < slots.length; i++) {
            logTimes += `${slots[i].timeChunk.startTime.format("h:mma")}-${slots[i].timeChunk.endTime.format("h:mma")}; `
        }
        // console.log("LogTimes: ", logTimes,slots);
        addSlotsToDays(slots);
    }
    const addSlotsToDays = (timesInDay:DayWithMomentChunk[]) =>{
        let duplicate = false
        for(let i = 0; i < loadedDays.value.length; i++){

            let loadedDay = loadedDays.value[i][0].day
            let day = timesInDay[0].day

            if(loadedDay === day){
                duplicate = true
            }
        }
        if(!duplicate) {
            loadedDays.value.push(timesInDay)
        }
        loadedDays.value.sort((a,b) => a[0].timeChunk.startTime.unix() - b[0].timeChunk.startTime.unix());
    }

    const checkStage = () =>{
        let stage = sessionStorage.getItem('stage')
        let rescheduleStage = sessionStorage.getItem('rescheduleStage')
        if(stage || rescheduleStage){
            if(stage == '1' || rescheduleStage == '1'){
                return false
            }
        }
        return true

    }
    checkStage()

    mixinResult = {
        senderUid: senderUid,
        meetingDate: meetingDate,
        meetingLength: meetingLength,
        isDateSelected: isDateSelected,
        loadedDays: loadedDays,
        earliestDayLoaded: earlistDayLoaded,
        getPreviousDay: getPreviousDay,
        getNextDay: getNextDay,
        setStart: setStart,
        setEnd: setEnd,
        formatDate: formatDate,
        events: events,
        blockedDays: blockedDays,
        disabledDays: disabledDays,
        killMixin: ()=>{
            hasInitialized = false
        },
        checkPreviousDay: checkPreviousDay,
        updateUserAvailability: updateUserAvailability
    };
    hasInitialized = true; 
}

