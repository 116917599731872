import {onAuthStateChanged, Unsubscribe, signInWithCustomToken, signOut, User, getIdTokenResult, ParsedToken} from 'firebase/auth'
import {auth, functions} from './firebase'
import { httpsCallable, HttpsCallableResult } from 'firebase/functions'
import {db, doc, updateDoc} from './firebase';
import { initRaygun, updateRaygunUser, setRaygunUserEventGenerator } from "./raygun-util";
import {computed, ref} from 'vue';

setRaygunUserEventGenerator((userData: User | null) => {
    if(userData) {
        return {
            identifier: userData.uid,
            email: userData.email || "",
            isAnonymous: false
        };
    } else {
        return {
            isAnonymous: true,
            identifier: '',
            email: ''
        }
    }
});

import { Userspaces } from '@busy-human/opt-library'
// import { clone, merge } from 'lodash';

/// == Offline/early sign in token ==
let loginToken = ref <string | undefined >()
const _loginTokenIndex = "opt.loginToken"
const saveLoginToken = async (token: string) => {
    localStorage.setItem(_loginTokenIndex, token);
}
const clearLoginToken = async () => {
    localStorage.removeItem(_loginTokenIndex);
    loginToken.value = undefined;
}
const loadLoginToken = async () => {
    const result = localStorage.getItem(_loginTokenIndex);
    return result || undefined;
}

let userId: string | undefined = undefined
const _userIdIndex = "opt.userId"
const saveUserId = async (userId: string) => {
    localStorage.setItem(_userIdIndex, userId);
}
const clearUserId = async () => {
    localStorage.removeItem(_userIdIndex);
    loginToken.value = undefined;
}
const loadUserId = async () => {
    const result = localStorage.getItem(_userIdIndex);
    return result || undefined;
}

const _claims = ref<ParsedToken>({});
const claims = computed(() => _claims.value);
const isAdmin = computed(() => !!_claims.value['admin'])

const getUserId = () => {
    if(auth.currentUser){
        // console.log("CURRENT USER:",auth.currentUser)
        return auth.currentUser.uid
    }
    else if(userId){
        // console.log("USER ID:",userId)
        return userId
    }
    else{
        return null
    }
}

let authChangeListener: Unsubscribe | undefined = undefined;
let _authWaitResolve: undefined | (() => void) = undefined;
/**
 * Sets up the auth listener and resolves when we can reasonably
 * believe we're logged in
 */
const initializeAuth = async () => {
    const _resolveOnce = () => {
        if(_authWaitResolve) {
            _authWaitResolve();
            _authWaitResolve = undefined;
        }
    }
    return new Promise<void>( async res => {
        _authWaitResolve = res;
        if(authChangeListener === undefined) {
            // Set up auth change listener
            authChangeListener = onAuthStateChanged(auth, async user => {
                if(!user) {
                    updateRaygunUser(null);
                    await clearLoginToken();
                    await clearUserId();
                    _claims.value = {};
                }else{
                    updateRaygunUser(user);
                    const tokenResult = await getIdTokenResult(user);
                    _claims.value = tokenResult.claims;
                    await saveUserId(user.uid)
                }
                console.log("[AUTH] Firebase auth ready");
                if(isAdmin.value) console.log("[AUTH] Admin Authenticated");
                _resolveOnce();
            });
        }
        loginToken.value = await loadLoginToken();
        // If we have an existing token, assume we've logged in
        // at least once before and can access data.
        // Otherwise wait for onAuthStateChanged to fire once
        if(loginToken.value) {
            userId = await loadUserId();
            _resolveOnce();
            console.log("[AUTH] User has signed in before, logging in early");
        } else {
            console.log("[AUTH] User has not signed in before, waiting for firebase");
        }
    })
}

const sendVerificationEmail = httpsCallable<{email: string}, VerificationResult>(functions, 'sendVerificationEmail');
const sendVerificationText = httpsCallable<{phone: string}, VerificationResult>(functions, 'sendVerificationText');

interface VerificationResult {
    /** Whether the action was successful */
    success: boolean;

    /** Omitted in beta and production environments */
    code?: string;
}

const sendVerification = async (verificationType:string, verificationInfo:string) =>{
    let result: HttpsCallableResult<VerificationResult>;
    let token: string | undefined;
    let success = false;
    if(verificationType == 'email'){
        result = await sendVerificationEmail({email: verificationInfo});
        token = result.data?.code;
        success = result.data?.success;
    }else if(verificationType == 'phone'){
        result = await sendVerificationText({phone: verificationInfo});
        token = result.data?.code;
        success = result.data?.success;
    }else{
        result = { data: {success: false} };
        console.log("[AUTH] Invalid Verification Type, token not generated");
    }

    return result;
}

interface DoSignupParams {
    firstName: string, 
    lastName: string, 
    email: string, 
    phone: string, 
    userDidSignupFromMeetingInvite: boolean,
    timezone: string | null
};
const doSignup = httpsCallable<DoSignupParams, string>(functions, 'createUser');
const signup = async (firstName: string, lastName: string, email: string, phone: string, userDidSignupFromMeetingInvite: boolean) => {
    let token: string | undefined;
    let timezone = null;
    try {
        timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    } catch(err) {
        console.warn("Error while resolving user's timezone");
        console.error(err);
    }
    const _token = await doSignup({firstName, lastName, email, phone, userDidSignupFromMeetingInvite, timezone});
    token = _token.data
}


const doLogin = httpsCallable<{email: string | undefined, phone: string | undefined, token: string}, string>(functions, 'doLogin');
const emailLogin = async (email: string, verificationToken:string) => {
    const _token = await doLogin({email: email, phone: undefined, token: verificationToken});
    await login(_token.data)
}
const phoneLogin = async (phone: string, verificationToken:string) => {
    const _token = await doLogin({email: undefined, phone: phone, token: verificationToken});
    await login(_token.data)
}
const login = async (newLoginToken: string) => {
    await signInWithCustomToken(auth, newLoginToken);
    await saveLoginToken(newLoginToken);
    loginToken.value = await loadLoginToken();
}
/**
 * Logs out the user
 */
const logout = async () => {
    await signOut(auth);
}
/**
 * A check to see if the user is currently logged into a company
 * @returns true if logged in, false if otherwise
 */
const checkLoggedIn = () => {
    return loginToken.value !== undefined;
}

const isLoggedIn = computed(() => {
    return loginToken.value !== undefined;
})

const doneWithRequiredSetup = async() => {
    let userId = getUserId()
    if(userId){
        let userDoc = await Userspaces.Collection.fetchDoc(getUserId()!)
        let userSetup = userDoc?.data()?.setupCompleted
        if(userDoc?.data()?.userDidSignupFromMeetingInvite){
            return true;
        }
        if(userSetup){
            if(userSetup.length < 2) return false
        }
    }
    return true
}

const doneWithSetup = async() => {
    let userId = getUserId()
    if(userId){
        let userDoc = await Userspaces.Collection.fetchDoc(getUserId()!)
        let userSetup = userDoc?.data()?.setupCompleted
        if(userSetup){
            if(userSetup.length < 3) return false
        }
    }
    return true
}

export {
    initializeAuth, 
    getUserId,

    sendVerification, 

    signup, 

    emailLogin, 
    phoneLogin, 

    logout, 

    checkLoggedIn,
    isLoggedIn,
    doneWithRequiredSetup,
    doneWithSetup,

    claims,
    isAdmin
}