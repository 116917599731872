import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "tabs" }
const _hoisted_2 = { class: "tabs-header" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "tab-wrapper" }
const _hoisted_5 = { class: "counter" }
const _hoisted_6 = {
  key: 0,
  class: "text"
}
const _hoisted_7 = {
  key: 0,
  class: "text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ul", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.defaultSlot, (tab, idx) => {
        return (_openBlock(), _createElementBlock("li", {
          class: _normalizeClass({selected: $setup.activeIdx == idx}),
          onClick: ($event: any) => ($setup.updateIndex(idx))
        }, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, _toDisplayString(tab.props!.counter), 1 /* TEXT */),
            _createVNode(_Transition, { name: "full-title" }, {
              default: _withCtx(() => [
                ($setup.activeIdx === idx)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(tab.props!.selectedTitle), 1 /* TEXT */))
                  : _createCommentVNode("v-if", true)
              ]),
              _: 2 /* DYNAMIC */
            }, 1024 /* DYNAMIC_SLOTS */),
            _createVNode(_Transition, { name: "short-title" }, {
              default: _withCtx(() => [
                ($setup.activeIdx !== idx)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(tab.props!.title), 1 /* TEXT */))
                  : _createCommentVNode("v-if", true)
              ]),
              _: 2 /* DYNAMIC */
            }, 1024 /* DYNAMIC_SLOTS */)
          ])
        ], 10 /* CLASS, PROPS */, _hoisted_3))
      }), 256 /* UNKEYED_FRAGMENT */))
    ]),
    _renderSlot(_ctx.$slots, "default")
  ]))
}