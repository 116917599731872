import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "date-header" }
const _hoisted_2 = { class: "meeting-date" }
const _hoisted_3 = { class: "month" }
const _hoisted_4 = { class: "date" }
const _hoisted_5 = {
  key: 0,
  class: "meeting-time"
}
const _hoisted_6 = { class: "weekday" }
const _hoisted_7 = { class: "time" }
const _hoisted_8 = {
  key: 1,
  class: "meeting-time"
}
const _hoisted_9 = { class: "name" }
const _hoisted_10 = { class: "time" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString($props.meetingMoment.format('MMM')), 1 /* TEXT */),
      _createElementVNode("div", _hoisted_4, _toDisplayString($props.meetingMoment.format('DD')), 1 /* TEXT */)
    ]),
    (!$props.name)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, "Meeting on " + _toDisplayString($props.meetingMoment.format('dddd')), 1 /* TEXT */),
          _createElementVNode("div", _hoisted_7, _toDisplayString($props.meetingMoment.format('h:mm A')), 1 /* TEXT */)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, "Meeting with " + _toDisplayString($props.name), 1 /* TEXT */),
          _createElementVNode("div", _hoisted_10, _toDisplayString($props.meetingMoment.format('H:mm A')), 1 /* TEXT */)
        ]))
  ]))
}