import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Transition as _Transition, withCtx as _withCtx, normalizeClass as _normalizeClass, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "loader-container flex-center"
}
const _hoisted_2 = {
  key: 1,
  class: "error-container"
}
const _hoisted_3 = {
  key: 2,
  class: "offer-preview-container center"
}
const _hoisted_4 = { class: "meeting-request-header" }
const _hoisted_5 = {
  key: 0,
  class: "text"
}
const _hoisted_6 = {
  key: 0,
  class: "page"
}
const _hoisted_7 = {
  key: 0,
  class: "page"
}
const _hoisted_8 = {
  key: 0,
  class: "page"
}
const _hoisted_9 = {
  key: 0,
  class: "page"
}
const _hoisted_10 = {
  key: 0,
  class: "page"
}
const _hoisted_11 = {
  key: 0,
  class: "page"
}
const _hoisted_12 = {
  key: 0,
  class: "page"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    ($setup.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode($setup["Loader"])
        ]))
      : _createCommentVNode("v-if", true),
    ($setup.error && $setup.invite)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("p", null, "An Error has Occurred: " + _toDisplayString($setup.error), 1 /* TEXT */),
          _createElementVNode("button", {
            class: "primary",
            onClick: $setup.returnToInvite
          }, "Try again")
        ]))
      : ($setup.selfMeeting && $setup.invite)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode($setup["OptOffer"], {
              offerLink: ($setup.route.params.offerLink as string),
              "is-preview": true,
              onClose: _cache[0] || (_cache[0] = ($event: any) => ($setup.routeTo('')))
            }, null, 8 /* PROPS */, ["offerLink"])
          ]))
        : ($setup.invite)
          ? (_openBlock(), _createElementBlock("div", {
              key: 3,
              class: _normalizeClass(["meeting-request-container", {'no-scroll': $setup.noScroll}])
            }, [
              _createElementVNode("div", _hoisted_4, [
                ($setup.stage != $setup.RequestStage.CONGRATS)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString($setup.request?.meetingLength || '') + " minute meeting with " + _toDisplayString($setup.person?.name?.fullName || ''), 1 /* TEXT */))
                  : _createCommentVNode("v-if", true)
              ]),
              _createVNode(_Transition, {
                onBeforeEnter: _cache[3] || (_cache[3] = ($event: any) => ($setup.noScroll = true)),
                onAfterLeave: _cache[4] || (_cache[4] = ($event: any) => ($setup.noScroll = false)),
                appear: ""
              }, {
                default: _withCtx(() => [
                  ($setup.stage === $setup.RequestStage.DATE && !$setup.loading)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                        _createVNode($setup["DateSelector"], {
                          class: "dateSelector",
                          request: $setup.request,
                          "onUpdate:request": _cache[1] || (_cache[1] = ($event: any) => (($setup.request) = $event)),
                          onProgress: _cache[2] || (_cache[2] = ($event: any) => ($setup.stage = $setup.RequestStage.TIME)),
                          onPrevious: $setup.returnToInvite,
                          "onUpdate:selectedDate": $setup.setSelectedDate,
                          onUpdateTimesAvailable: $setup.updateTimesAvailable,
                          invite: $setup.invite,
                          events: $setup.events
                        }, null, 8 /* PROPS */, ["request", "invite", "events"])
                      ]))
                    : _createCommentVNode("v-if", true)
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_Transition, {
                onBeforeEnter: _cache[8] || (_cache[8] = ($event: any) => ($setup.noScroll = true)),
                onAfterLeave: _cache[9] || (_cache[9] = ($event: any) => ($setup.noScroll = false)),
                appear: ""
              }, {
                default: _withCtx(() => [
                  ($setup.stage === $setup.RequestStage.TIME)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                        _createVNode($setup["TimeSelector"], {
                          request: $setup.request,
                          "onUpdate:request": [
                            _cache[5] || (_cache[5] = ($event: any) => (($setup.request) = $event)),
                            $setup.updateRequest
                          ],
                          onProgress: _cache[6] || (_cache[6] = ($event: any) => ($setup.stage = $setup.RequestStage.NOTE)),
                          onPrevious: _cache[7] || (_cache[7] = ($event: any) => ($setup.stage = $setup.RequestStage.DATE)),
                          invite: $setup.invite,
                          events: $setup.events,
                          selectedDate: $setup.selectedDate,
                          timesAvailable: $setup.timesAvailable,
                          checkPreviousDay: $setup.checkPreviousDay
                        }, null, 8 /* PROPS */, ["request", "invite", "events", "selectedDate", "timesAvailable", "checkPreviousDay"])
                      ]))
                    : _createCommentVNode("v-if", true)
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_Transition, {
                onBeforeEnter: _cache[14] || (_cache[14] = ($event: any) => ($setup.noScroll = true)),
                onAfterLeave: _cache[15] || (_cache[15] = ($event: any) => ($setup.noScroll = false)),
                appear: ""
              }, {
                default: _withCtx(() => [
                  ($setup.stage === $setup.RequestStage.INFO)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                        _createVNode($setup["MiniAuth"], {
                          loading: $setup.loading,
                          "onUpdate:loading": _cache[10] || (_cache[10] = ($event: any) => (($setup.loading) = $event)),
                          onProgress: _cache[11] || (_cache[11] = ($event: any) => ($setup.stage = $setup.RequestStage.CONTACT)),
                          onPrevious: _cache[12] || (_cache[12] = ($event: any) => ($setup.stage = $setup.RequestStage.NOTE)),
                          onLogin: _cache[13] || (_cache[13] = ($event: any) => ($setup.updateUserDetails()))
                        }, null, 8 /* PROPS */, ["loading"])
                      ]))
                    : _createCommentVNode("v-if", true)
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_Transition, {
                onBeforeEnter: _cache[19] || (_cache[19] = ($event: any) => ($setup.noScroll = true)),
                onAfterLeave: _cache[20] || (_cache[20] = ($event: any) => ($setup.noScroll = false)),
                appear: ""
              }, {
                default: _withCtx(() => [
                  ($setup.stage === $setup.RequestStage.NOTE)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                        _createVNode($setup["NoteToSender"], {
                          request: $setup.request,
                          "onUpdate:request": [
                            _cache[16] || (_cache[16] = ($event: any) => (($setup.request) = $event)),
                            $setup.updateRequest
                          ],
                          onProgress: _cache[17] || (_cache[17] = ($event: any) => ($setup.stage = $setup.checkLoggedIn() ? $setup.RequestStage.CONTACT : $setup.RequestStage.INFO)),
                          onPrevious: _cache[18] || (_cache[18] = ($event: any) => ($setup.stage = $setup.RequestStage.TIME)),
                          person: $setup.person,
                          invite: $setup.invite
                        }, null, 8 /* PROPS */, ["request", "person", "invite"])
                      ]))
                    : _createCommentVNode("v-if", true)
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_Transition, {
                onBeforeEnter: _cache[24] || (_cache[24] = ($event: any) => ($setup.noScroll = true)),
                onAfterLeave: _cache[25] || (_cache[25] = ($event: any) => ($setup.noScroll = false)),
                appear: ""
              }, {
                default: _withCtx(() => [
                  ($setup.stage === $setup.RequestStage.CONTACT)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                        _createVNode($setup["ContactDetails"], {
                          request: $setup.request,
                          "onUpdate:request": [
                            _cache[21] || (_cache[21] = ($event: any) => (($setup.request) = $event)),
                            $setup.updateRequest
                          ],
                          onProgress: _cache[22] || (_cache[22] = ($event: any) => ($setup.stage = $setup.RequestStage.CONFIRM)),
                          onPrevious: _cache[23] || (_cache[23] = ($event: any) => ($setup.stage = $setup.RequestStage.NOTE))
                        }, null, 8 /* PROPS */, ["request"])
                      ]))
                    : _createCommentVNode("v-if", true)
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_Transition, {
                onBeforeEnter: _cache[28] || (_cache[28] = ($event: any) => ($setup.noScroll = true)),
                onAfterLeave: _cache[29] || (_cache[29] = ($event: any) => ($setup.noScroll = false)),
                appear: ""
              }, {
                default: _withCtx(() => [
                  ($setup.stage === $setup.RequestStage.CONFIRM)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                        _createVNode($setup["ConfirmAndPay"], {
                          request: $setup.request,
                          paymentMethodId: $setup.paymentMethodId,
                          "onUpdate:paymentMethodId": _cache[26] || (_cache[26] = ($event: any) => (($setup.paymentMethodId) = $event)),
                          onProgress: $setup.submitMeeting,
                          onPrevious: _cache[27] || (_cache[27] = ($event: any) => ($setup.stage = $setup.RequestStage.CONTACT)),
                          user: $setup.user
                        }, null, 8 /* PROPS */, ["request", "paymentMethodId", "user"])
                      ]))
                    : _createCommentVNode("v-if", true)
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_Transition, {
                onBeforeEnter: _cache[31] || (_cache[31] = ($event: any) => ($setup.noScroll = true)),
                onAfterLeave: _cache[32] || (_cache[32] = ($event: any) => ($setup.noScroll = false)),
                appear: ""
              }, {
                default: _withCtx(() => [
                  ($setup.stage === $setup.RequestStage.CONGRATS)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                        _createVNode($setup["MeetingRequested"], {
                          request: $setup.request,
                          onProgress: _cache[30] || (_cache[30] = ($event: any) => ($setup.routeTo(''))),
                          user: $setup.person
                        }, null, 8 /* PROPS */, ["request", "user"])
                      ]))
                    : _createCommentVNode("v-if", true)
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode($setup["AccountToast"], {
                time: 5000,
                showToast: $setup.showToast,
                user: $setup.user,
                onCloseToast: _cache[33] || (_cache[33] = ($event: any) => ($setup.showToast = false)),
                onSetSigninFalse: _cache[34] || (_cache[34] = ($event: any) => ($setup.signedIn = false))
              }, null, 8 /* PROPS */, ["showToast", "user"])
            ], 2 /* CLASS */))
          : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}