import { auth } from "@/util/firebase";
import { DataInstance, SnapshotEventType } from "@busy-human/gearbox";
import { FinancialUserspaces, Userspaces } from "@busy-human/opt-library";
import { onAuthStateChanged } from "firebase/auth";
import { defineStore } from "pinia";
import { computed, ref } from "vue";
import { DocumentReference, onSnapshot, Unsubscribe } from 'firebase/firestore'

export const useUserspace = defineStore('userspace', () => {
    const user = ref<DataInstance<Userspaces.Model>>({...Userspaces.Defaults(), $id: ''});
    const financial = ref<DataInstance<FinancialUserspaces.Model>>({...FinancialUserspaces.Defaults(), $id: ''});

    const idOverride = ref<string | null>(null);

    const cleanup = ref<Unsubscribe | null>(null);

    // I'm bypassing gearbox's listener because it's broken
    function setupListener(uid: string) {
        const ref = Userspaces.Collection.docRef(uid);
        const fref = FinancialUserspaces.Collection.docRef(uid);
        
        const uCleanup = onSnapshot(ref, snap => {
            const data = snap.data() as Userspaces.Model | undefined;
            if(data) user.value = { ... data, $id: snap.id }
        })
        const fCleanup = onSnapshot(fref, snap => {
            const data = snap.data() as FinancialUserspaces.Model | undefined;
            if(data) financial.value = { ... data, $id: snap.id }
        })

        cleanup.value = () => {
            uCleanup(); fCleanup(); 
        }
    }
    function cleanupListener() {
        if(cleanup.value) {
            cleanup.value();
            cleanup.value = null;
        }
    }

    async function setUserId(uid: string, asProxy = false) {
        cleanupListener();

        if(asProxy)
            idOverride.value = uid;
        user.value = await Userspaces.Collection.fetchData(uid);
        financial.value = await FinancialUserspaces.Collection.fetchData(uid);
        
        setupListener(uid);
    }

    async function resetSpace(signOut?: boolean) {
        cleanupListener();

        if(signOut || !auth.currentUser) {
            user.value = { ... Userspaces.Defaults(), $id: '' }
            financial.value = { ... FinancialUserspaces.Defaults(), $id: '' }
        } else {
            user.value = await Userspaces.Collection.fetchData(auth.currentUser.uid);
            financial.value = await FinancialUserspaces.Collection.fetchData(auth.currentUser.uid);

            setupListener(auth.currentUser.uid);
        }

        idOverride.value = null;
    }

    onAuthStateChanged(auth, async user => {
        if(user) {
            await setUserId(user.uid);
        } else {
            await resetSpace(true);
        }
    });

    const uid = computed(() => idOverride.value || user.value.$id);

    const isProxy = computed(() => !!idOverride.value);

    return { user, financial, setUserId, uid, resetSpace, isProxy }
})