import { GoogleAuthProvider, linkWithPopup, unlink, linkWithCredential } from 'firebase/auth'
import { auth, functions, httpsCallable } from './firebase'
// import { OAuth2Client } from 'google-auth-library'
import { Calendars, Userspaces } from '@busy-human/opt-library';
import { CollectionEntityInterface } from '@busy-human/gearbox';
import { useUserspace } from '@/store/userspace';
// const {google} = require('googleapis');

const CLIENT_ID = CONFIG.googleCalendar.clientId;
const SCOPES = 'https://www.googleapis.com/auth/calendar';

var googleProvider = new GoogleAuthProvider()
googleProvider.addScope('https://www.googleapis.com/auth/calendar')

// const authClient = new OAuth2Client({
//     "clientId": OAuth2Json.client_id,
//     "clientSecret": OAuth2Json.client_secret,
//     "redirectUri": 'https://us-central1-leanmeet-optme.cloudfunctions.net/finishGoogleSetup'
// })

const TOKEN_LENGTH = 32;

function makeID(length: number) {
    const vals: string[] = [];
    const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for(let i = 0; i < length; i++) {
        const x = Math.floor(Math.random() * chars.length);
        vals.push(chars[x]);
    }
    return vals.join('');
} 

const calendarApi = {

    linkGoogle: async (popupInstance: Window | null) => {
        const Cals: CollectionEntityInterface<Calendars.OptCalendar>
            = Userspaces.Collection.subCollectionEntity(useUserspace().uid, 'calendars');

        const calRef = Cals.docEntity('google');
        const calInstance = await calRef.fetch();

        const token = makeID(TOKEN_LENGTH);

        const calData = Calendars.DefaultGoogle();
        calData.stateToken = token;
        if(calInstance.calendarID && calInstance.calendarID !== '')
            calData.calendarID = calInstance.calendarID;

        await calRef.set(calData);

        const state = btoa(JSON.stringify(
            { token, uid: useUserspace().uid }
        ))

        const url = new URL("https://accounts.google.com/o/oauth2/v2/auth")
        url.searchParams.set('state', state);
        url.searchParams.set('access_type', 'offline');
        url.searchParams.set('scope', SCOPES);
        url.searchParams.set('login_hint', auth.currentUser?.email || '');
        url.searchParams.set('prompt', 'consent');
        url.searchParams.set('response_type', 'code');
        url.searchParams.set('redirect_uri', `https://us-central1-${CONFIG.firebase.config.projectId}.cloudfunctions.net/finishGoogleSetup`);
        url.searchParams.set('client_id', CLIENT_ID);

        if(!popupInstance) return {
            success: false,
            error: "Popup failed"
        }
        popupInstance.location.href = url.toString();

        let resolve: () => void
        let reject: (error: any) => void
        const promise = new Promise<void>((res, rej) => {
            resolve = res;
            reject = rej;
        });

        let popupClosed = setInterval(function() {
            if(popupInstance.closed){
                clearInterval(popupClosed);
                reject("Popup closed by User")
            }
        }, 250)

        calRef.onUpdate(event => {
            if (calRef.data().refreshToken !== '') resolve();
            if (typeof calRef.data().error !== 'undefined') reject(calRef.data().error);
        })
        calRef.listen();
        let timeout = setTimeout(() => reject("Request timed out"), 5 * 60 * 1000);

        let success = false;
        let error: any = null;

        try {
            await promise;
            const data = calRef.data();
            success = true;
        } catch (e) {
            console.error(e);
            error = e;
            await calendarApi.unlinkGoogle();
        } finally {
            calRef.cleanup();
            clearTimeout(timeout);
        }

        return {success, error};
        // We aren't actually linking the firebase account here to google
        // (because I can't figure out how to get an id_token from anything)
    },
    unlinkGoogle: async () => {
        const unlinkGoogle = httpsCallable<void | { uid?: string }, any>(functions, 'unlinkGoogle');
        const res = await unlinkGoogle({uid: useUserspace().uid});
        console.log("Unlinked Google:", res);
    },
}

export default calendarApi
