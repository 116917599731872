
import { useRouter } from 'vue-router';
export default {
    setup() {
        const router = useRouter();

        const goTo = (path: string, hash: string) =>{
            let route:any = {path:path}
            if(hash)
                route['hash'] = hash
            router.push(route)
        }
        return { goTo }
    }
}
