import { createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "qr-code-container" }
const _hoisted_2 = {
  ref: "canvas",
  width: 250,
  height: 250
}
const _hoisted_3 = {
  key: 0,
  class: "loading-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("canvas", _hoisted_2, null, 512 /* NEED_PATCH */),
    ($setup.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode($setup["VLoader"], { "hide-tips": true })
        ]))
      : _createCommentVNode("v-if", true)
  ]))
}