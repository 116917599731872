export const setupText = {
    payment: {
        getPaid: "Get Paid for your Time",
        optCreates: "Opt creates a standard link for you to share with the public through your email signature, website, social media and more. Don't worry, you can create unlimited unique links for specific meeting types or people.",
        thisWillBe: "This will be the standard meeting rate when sending an Opt Offer, but it can still be changed during the creation process to better suit the meeting you're trying to hold.",
        yourStRate: "Your Standard Meeting",
        editStMtgRt: "Edit Standard Meeting",
        price: "Price",
        duration: "Duration",
    },
};