import { DataInstance, InviteLinks } from "@busy-human/opt-library";
import { functions, httpsCallable } from "./firebase";
import { FunctionTypes } from "@busy-human/opt-library";


export const sendVerificationEmail = httpsCallable<{email: string}, string>(functions, 'sendVerificationEmail');
export const sendVerificationText = httpsCallable<{phone: string}, string>(functions, 'sendVerificationText');
export const createUser = httpsCallable<{firstName: string, lastName: string, email: string, phone: string}, string>(functions, 'createUser');
export const doLogin = httpsCallable<{email: string | undefined, phone: string | undefined, token: string}, string>(functions, 'doLogin');

export const getUserAvailability = httpsCallable<{uid: string, year: number, month: number}, {startTime: string, endTime: string}[]>(functions, 'getUserAvailability');

export const createInviteLink = httpsCallable<{data: InviteLinks.Model, uid?: string}, DataInstance<InviteLinks.Model>>(functions, 'createInviteLink');
export const onboardPaymentAccount = httpsCallable<FunctionTypes.OnboardPaymentAccount.OnboardPaymentAccountData, FunctionTypes.OnboardPaymentAccount.OnboardPaymentAccountReturn>(functions, 'onboardPaymentAccount');

export const beginMicrosoftSetup = httpsCallable<void | { uid?: string}, string>(functions, 'beginMicrosoftSetup');

interface UserFeeArguments {
    discountCode?: string
    uid?: string
}
interface UserFeeResponse {
    fee: number
}

export const getUserFee = async (args: UserFeeArguments = {}) => {
    const res = await fetch("https://us-central1-leanmeet-optme.cloudfunctions.net/getUserFee", {
        body: JSON.stringify(args),
        headers: {
            'Content-Type': 'application/json'
        },
        method: 'POST'
    });
    const result = await res.json();
    if(result.error) {
        throw new Error(result.error);
    } else {
        return result as UserFeeResponse;
    }
}