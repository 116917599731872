import { DataInstance, Meetings } from "@busy-human/opt-library";
import { onSnapshot, query, Unsubscribe, where } from "firebase/firestore";
import { uniqBy } from "lodash";
import { defineStore } from "pinia";
import { computed, ref, watch } from "vue";
import { useUserspace } from './userspace';

export const useMeetings = defineStore("meetings", () => {
    const payerMeetings = ref<DataInstance<Meetings.Model>[]>([]);
    const collectorMeetings = ref<DataInstance<Meetings.Model>[]>([]);
    const listenerCleanup = ref<Unsubscribe | null>(null);

    async function setup(uid: string) {
        // Preemptive cleanup
        if(listenerCleanup.value) listenerCleanup.value();

        const payerRef = Meetings.Collection
            .subset(ref => query(ref, where('payer', '==', uid)));
        const collectorRef = Meetings.Collection
            .subset(ref => query(ref, where('collector', '==', uid)))

        payerMeetings.value = (await payerRef.fetch()).map(doc => doc.data());
        collectorMeetings.value = (await collectorRef.fetch()).map(doc => doc.data());
        
        const payerCleanup = onSnapshot(payerRef.ref(), snap => {
            const data: DataInstance<Meetings.Model>[] = 
                snap.docs.map(docSnap => 
                    ({... docSnap.data() as Meetings.Model, 
                    $id: docSnap.id
                }));
            payerMeetings.value = data;
        });
        const collectorCleanup = onSnapshot(collectorRef.ref(), snap => {
            const data: DataInstance<Meetings.Model>[] = 
                snap.docs.map(docSnap => 
                    ({... docSnap.data() as Meetings.Model, 
                    $id: docSnap.id
                }));
            collectorMeetings.value = data;
        })
        listenerCleanup.value = () => { payerCleanup(); collectorCleanup() }
    }

    function cleanup() {
        if(listenerCleanup.value) listenerCleanup.value();

        payerMeetings.value = [];
        collectorMeetings.value = [];
    }

    const userspace = useUserspace();

    watch(() => userspace.uid, async uid => {
        if(uid) await setup(uid);
        else cleanup();
    }, { immediate: true });

    const list = computed(() => 
        uniqBy([...payerMeetings.value, ...collectorMeetings.value],
            (i => i.$id))
        .sort((a, b) => a.$id.localeCompare(b.$id)));

    return { list }
})