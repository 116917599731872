import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "code" }
const _hoisted_2 = ["type", "disabled", "onFocus", "onInput", "onKeydown", "onPaste"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.length, (index) => {
      return (_openBlock(), _createElementBlock("input", {
        ref_for: true,
        ref: "inputs",
        type: "number",
        placeholder: " ",
        pattern: "[0-9]*",
        inputmode: "numeric",
        maxlength: "1",
        disabled: $props.disabled,
        onFocus: ($event: any) => ($setup.onFocus(index - 1)),
        onInput: ($event: any) => ($setup.handleInput($event, index - 1)),
        onKeydown: ($event: any) => ($setup.handleKeydown($event, index - 1)),
        onPaste: _withModifiers(($event: any) => ($setup.handlePaste($event, index - 1)), ["prevent"])
      }, null, 40 /* PROPS, HYDRATE_EVENTS */, _hoisted_2))
    }), 256 /* UNKEYED_FRAGMENT */))
  ]))
}