import {createRouter, createWebHashHistory} from "vue-router";
import { doneWithRequiredSetup, doneWithSetup, checkLoggedIn, isAdmin } from "./util/auth";
import { useUserspace } from "./store/userspace";
// import { FunctionTypes } from "@busy-human/opt-library/src/functions";

const returnPath = "paymentOnboardingComplete";

// Routes are lazy loaded (README: Vue Router > Lazy Loading)
const Landing = () => import("./views/landing.vue");
const LoginView = () => import("./views/auth/auth.vue");
const NewLoginView = () => import("./views/auth/new-user-auth.vue");
const SetupView = () => import("./views/setup.vue");
const HomeView = () => import("./views/home.vue");
const BalanceView = () => import("./views/balance.vue");
const NewInvite = () => import("@/views/new-invite.vue");
const MeetingView = () => import("./views/meetings.vue");
const Referral = () => import("./views/referral.vue");
const OfferView = () => import("@/views/invite.vue");
// const MeetingRequestView = () => import("@/views/meeting-request.vue");
const MeetingRequestView = () => import("@/views/meeting-request-2.vue");
const MeetingRescheduleView = () => import("@/views/meeting-reschedule.vue");
const ActiveLinks = () => import("@/views/active-links.vue");
// const Profile = () => import("@/views/profile.vue")
const Settings = () => import("@/views/settings.vue")
// const MissedMeeting = () => import("@/views/missed-meeting.vue");
const WithdrawBalance = () => import("@/views/withdraw-balance.vue");
const RetryPayment = () => import("@/views/retry-payment.vue");
const CheckIn = () => import("@/views/check-in.vue");
const PaymentAccountSuccess = () => import("@/views/payment-account-success.vue");
const PostCheckout = () => import("@/views/post-checkout.vue");

const PrivacyPolicy = () => import("@/views/privacy-policy.vue");
const TermsOfService = () => import("@/views/terms-of-service.vue");

const AdminProxy = () => import("@/views/proxy.vue");

const Tester = () => import("./views/tester.vue")

const router = createRouter({
    // We should really use HTML5 History
    history: createWebHashHistory(),
    routes: [
        // meta options:
        /// requiresAuth: if true, will check if the user is logged in before navigating
        /// useFullscreen: if true, uses a fullscreen view for the layout
        {
            path: "/invite/:offerLink",
            name: "invite",
            component: OfferView,
            meta: {
                requiresAuth: false
            }
        },

        {
            path: "/meeting-request/:offerLink",
            name: "meeting-request",
            component: MeetingRequestView,
            meta: {
                requiresAuth: false
            }
        },

        {
            path: "/meeting-reschedule/:meetingId",
            name: "meeting-reschedule",
            component: MeetingRescheduleView,
            meta: {
                requiresAuth: true
            }
        },

        // {
        //     path: "/missed-meeting/:meetingLink",
        //     name: "missed-meeting",
        //     component: MissedMeeting,
        //     meta: {
        //         requiresAuth: false
        //     }
        // },

        {
            path: "/withdraw-balance/:withdrawParams",
            name: "withdraw-balance",
            component: WithdrawBalance,
            meta: {
                requiresAuth: false,
                redHeader: true,
            }
        },

        {
            path: "/retry-payment/:meetingLink",
            name: "retry-payment",
            component: RetryPayment,
            meta: {
                requiresAuth: false
            }
        },

        {
            path: "/check-in/:meetingLink",
            name: "check-in",
            component: CheckIn,
            meta: {
                requiresAuth: false
            }
        },

        {
            path: "/landing",
            name: "landing",
            component: Landing,
            meta: {
                useFullscreen: true,
                requiresAuth: false
            }
        },

        {
            path: "/auth",
            component: LoginView,
            name: "login",
            meta: {
                useFullscreen: true,
                requiresAuth: false
            }
        },

        {
            path: "/auth-new",
            component: NewLoginView,
            name: "new-login",
            meta: {
                useFullscreen: true,
                requiresAuth: false
            }
        },

        {
            path: "/links",
            component: ActiveLinks,
            name: "active-links",
            meta: {
                useFullscreen: true,
                requiresAuth: true,
                redHeader: true,
                // hideProfile: true,
            }
        },

        {
            path: "/setup",
            name: "setup",
            component: SetupView,
            meta: {
                useFullscreen: true,
                requiresAuth: true // probably should be false
            }
        },

        {
            path: "/",
            name: "home",
            component: HomeView,
            meta: {
                requiresAuth: true,
                hideProfile: true,
            }
        },

        {
            path: "/balance",
            name: "balance",
            component: BalanceView,
            meta: {
                requiresAuth: true,
                redHeader: true,
            }
        },

        {
            path: "/meetings",
            name: "meetings",
            component: MeetingView,
            meta: {
                requiresAuth: true,
                redHeader: true,
            }
        },

        {
            path: "/new-invite",
            component: NewInvite,
            name: "new-invite",
            meta: {
                requiresAuth: true
            }
        },

        {
            path: "/referral",
            component: Referral,
            name: "referral",
            meta: {
                requiresAuth: true
            }
        },
        // {
        //     path: '/profile',
        //     name: 'profile',
        //     component: Profile,
        //     meta: {
        //         useFullscreen: true
        //     }
        // },

        {
            path: '/settings',
            name: 'settings',
            component: Settings,
            meta: {
                hideProfile: true,
                useFullscreen: true,
                requiresAuth: true,
                redHeader: true,
            }
        },

        {
            path: '/tester',
            name: 'tester',
            component: Tester,
            meta:{
                useFullscreen: true
            }
        },

        {
            path: `/${returnPath}`,
            name: "Payment Account Success",
            component: PaymentAccountSuccess
        },

        {
            path: `/post-checkout`,
            name: "Checkout Complete",
            component: PostCheckout
        },

        {
            path: `/privacy`,
            name: "Privacy Policy",
            component: PrivacyPolicy
        },

        {
            path: `/terms-of-service`,
            name: "Terms of Service",
            component: TermsOfService
        },
        {
            path: '/proxy',
            name: "Admin Proxy",
            component: AdminProxy,
            meta: {
                requiresAdmin: true,
                requiresAuth: true
            }
        }
    ]
});

router.beforeEach(async(to, from) => {
    let isLoggedIn = checkLoggedIn()
    const userAdmin = isAdmin.value;

    // Always allow an invite
    if(to.name === "invite") return;

    // If the page requires authorization and the visitor is not logged in
    if(to.meta.requiresAuth && !isLoggedIn) return { name: 'login' }

    if(to.meta.requiresAdmin && !userAdmin) return { name: 'home' }

    //Don't Go to login if they're already logged in
    if(to.name === 'login' && isLoggedIn) return { name: 'home' }
    
    let requiredSetupComplete = await doneWithRequiredSetup()
    let setupComplete = await doneWithSetup()
    if(isLoggedIn){
        const userspace = useUserspace();
        if(setupComplete && to.name === 'setup'){
            return { name: 'home' }
        }
        else if(to.meta.requiresAuth && !requiredSetupComplete && to.name !== 'setup' && !userspace.user.userDidSignupFromMeetingInvite){
            return {name: 'setup'}
        }
    }
    
})

export default router;