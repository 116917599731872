import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7f6287d9"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "upcoming-meeting"
}
const _hoisted_2 = { class: "month" }
const _hoisted_3 = { class: "day" }
const _hoisted_4 = { class: "meeting-description center" }
const _hoisted_5 = { class: "meeting-details" }
const _hoisted_6 = { class: "recipient-string" }
const _hoisted_7 = {
  key: 0,
  class: "meeting-info"
}
const _hoisted_8 = {
  key: 1,
  class: "meeting-info"
}
const _hoisted_9 = {
  key: 2,
  class: "meeting-info"
}
const _hoisted_10 = {
  key: 3,
  class: "meeting-info"
}
const _hoisted_11 = { class: "meeting-icon" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($props.meeting && !($props.meeting.status === 'canceled'))
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
          class: _normalizeClass(["meeting-date", {previous: $props.previous}])
        }, [
          _createElementVNode("div", _hoisted_2, _toDisplayString($setup.month), 1 /* TEXT */),
          _createElementVNode("div", _hoisted_3, _toDisplayString($setup.day), 1 /* TEXT */)
        ], 2 /* CLASS */),
        _createElementVNode("div", {
          class: _normalizeClass(["meeting-body", {failingPayment: $props.meeting.paymentStatus === 'failed'}]),
          onClick: $setup.handlePopup
        }, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, _toDisplayString($setup.recipientName) + " at " + _toDisplayString($setup.meetingTime), 1 /* TEXT */),
              ($setup.meetingLocation === 'video')
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString($setup.meetingDuration) + " mins in a Video Call", 1 /* TEXT */))
                : ($setup.meetingLocation === 'phone')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString($setup.meetingDuration) + " mins in a Phone Call", 1 /* TEXT */))
                  : ($setup.meetingLocation === 'physical')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString($setup.meetingDuration) + " mins in " + _toDisplayString($setup.city) + ", " + _toDisplayString($setup.state), 1 /* TEXT */))
                    : ($setup.meetingLocation === 'other')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString($setup.meetingDuration) + " mins - See note for details", 1 /* TEXT */))
                      : _createCommentVNode("v-if", true)
            ]),
            _createElementVNode("div", _hoisted_11, [
              ($setup.youPayMe)
                ? (_openBlock(), _createBlock($setup["VPaidIcon"], {
                    key: 0,
                    type: "YouPayMe"
                  }))
                : (_openBlock(), _createBlock($setup["VPaidIcon"], {
                    key: 1,
                    payout: "",
                    type: "IPayYou"
                  }))
            ]),
            _createElementVNode("div", {
              class: _normalizeClass(["meeting-payment", {payout:!$setup.youPayMe}])
            }, _toDisplayString($props.meeting.price.currency) + _toDisplayString($props.meeting.price.amount), 3 /* TEXT, CLASS */)
          ])
        ], 2 /* CLASS */)
      ]))
    : _createCommentVNode("v-if", true)
}