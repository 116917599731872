import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["label", "id", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("input", {
    class: _normalizeClass(["checkbox", {isActive: $setup.isChecked}]),
    type: "checkbox",
    label: $setup.props.label,
    id: $setup.props.id,
    disabled: $setup.isDisabled,
    onInput: _cache[0] || (_cache[0] = ($event: any) => ($setup.updateValue(!$setup.isChecked)))
  }, null, 42 /* CLASS, PROPS, HYDRATE_EVENTS */, _hoisted_1))
}