import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, withModifiers as _withModifiers, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "modal-card-contents" }
const _hoisted_2 = { class: "modal-card-buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    _createElementVNode("div", {
      class: "modal-scrim",
      onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_ctx.$emit('close')), ["stop"]))
    }, [
      _createElementVNode("div", {
        class: "modal-card",
        onClick: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["stop"]))
      }, [
        _createElementVNode("div", _hoisted_1, [
          _renderSlot(_ctx.$slots, "default")
        ]),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("button", {
            class: "g-button g-save",
            onClick: _cache[0] || (_cache[0] = ($event: any) => {_ctx.$emit('no'); _ctx.$emit('close')})
          }, "No"),
          _createElementVNode("button", {
            class: "g-button g-warning",
            onClick: _cache[1] || (_cache[1] = ($event: any) => {_ctx.$emit('yes'); _ctx.$emit('close')})
          }, "Yes")
        ])
      ])
    ])
  ]))
}