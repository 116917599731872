import {initializeApp} from 'firebase/app';
import {getFirestore, enableMultiTabIndexedDbPersistence, Timestamp} from 'firebase/firestore';
import {getAuth} from 'firebase/auth';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { Globals } from '@busy-human/gearbox';
import { updateDoc, doc, FieldValue } from "firebase/firestore"
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage' 

// TODO: MOVE THIS INTO ITS OWN global.d.ts
declare global {
    const CONFIG: any;
}

const app = initializeApp( CONFIG.firebase.config );
export const auth = getAuth(app);
export const db = getFirestore(app);
export const functions = getFunctions(app, 'us-central1');
export const storage = getStorage(app)
export {Timestamp, httpsCallable}

Globals.setFirestore(db);

enableMultiTabIndexedDbPersistence(db).then(() => {
    console.log("[FIRESTORE] IndexedDB Persistence/Caching enabled");
}).catch(err => {
    console.log("[FIRESTORE] IndexedDB Caching failed, disabled");
    console.error(err);
})
export {updateDoc, FieldValue, doc, ref, uploadBytesResumable, getDownloadURL}