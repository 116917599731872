import { FinancialUserspaces } from '@busy-human/opt-library';
import { ref, computed } from 'vue';
import { useUserspace } from '@/store/userspace';

export function useBalance() {
    const eventualBalance = FinancialUserspaces.eventualBalance;
    const availableBalance = FinancialUserspaces.availableBalance;
    const pendingBalance = FinancialUserspaces.pendingBalance;
    const userspace = useUserspace();

    const balances = {
        eventual: computed(() => eventualBalance(userspace.financial)),
        available: computed(() => availableBalance(userspace.financial)),
        pending: computed(() => pendingBalance(userspace.financial)),
    }

    return balances;
}