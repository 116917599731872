import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "time-scroller" }
const _hoisted_2 = { class: "day" }
const _hoisted_3 = { class: "meeting-date" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "meeting-time" }
const _hoisted_6 = { class: "time" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "days-container",
      ref: "scrollContainer",
      onWheel: $setup.handleWheel
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.availability, (day, index) => {
        return (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(day[0]?.toLocaleDateString() || ''), 1 /* TEXT */),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(day, (time) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "meeting-times",
              onClick: ($event: any) => (_ctx.$emit('update:modelValue', time))
            }, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, _toDisplayString(time.toLocaleTimeString()), 1 /* TEXT */)
              ])
            ], 8 /* PROPS */, _hoisted_4))
          }), 256 /* UNKEYED_FRAGMENT */))
        ]))
      }), 256 /* UNKEYED_FRAGMENT */))
    ], 544 /* HYDRATE_EVENTS, NEED_PATCH */)
  ]))
}