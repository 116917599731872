import { DataInstance, Transactions } from "@busy-human/opt-library";
import { onSnapshot, query, Unsubscribe, where } from "firebase/firestore";
import { uniqBy } from "lodash";
import { defineStore } from "pinia";
import { computed, ref, watch } from "vue";
import { useUserspace } from './userspace';

export const useTransactions = defineStore("transactions", () => {
    const sourceTransactions = ref<DataInstance<Transactions.Model>[]>([]);
    const targetTransactions = ref<DataInstance<Transactions.Model>[]>([]);
    const listenerCleanup = ref<Unsubscribe | null>(null);

    async function setup(uid: string) {
        // Preemptive cleanup
        if(listenerCleanup.value) listenerCleanup.value();

        const sourceRef = Transactions.Collection
            .subset(ref => query(ref, where('source.scope', '==', uid)));
        const targetRef = Transactions.Collection
            .subset(ref => query(ref, where('target.scope', '==', uid)))

        sourceTransactions.value = (await sourceRef.fetch()).map(doc => doc.data());
        targetTransactions.value = (await targetRef.fetch()).map(doc => doc.data());
        
        const sourceCleanup = onSnapshot(sourceRef.ref(), snap => {
            const data: DataInstance<Transactions.Model>[] = 
                snap.docs.map(docSnap => 
                    ({... docSnap.data() as Transactions.Model, 
                    $id: docSnap.id
                }));
            sourceTransactions.value = data;
        });
        const targetCleanup = onSnapshot(targetRef.ref(), snap => {
            const data: DataInstance<Transactions.Model>[] = 
                snap.docs.map(docSnap => 
                    ({... docSnap.data() as Transactions.Model, 
                    $id: docSnap.id
                }));
            targetTransactions.value = data;
        })
        listenerCleanup.value = () => { sourceCleanup(); targetCleanup() }
    }

    function cleanup() {
        if(listenerCleanup.value) listenerCleanup.value();

        sourceTransactions.value = [];
        targetTransactions.value = [];
    }

    const userspace = useUserspace();

    watch(() => userspace.uid, async uid => {
        if(uid) await setup(uid);
        else cleanup();
    }, { immediate: true });

    const list = computed(() => 
        uniqBy([...sourceTransactions.value, ...targetTransactions.value],
            (i => i.$id))
        .sort((a, b) => a.createdAt.localeCompare(b.createdAt)));

    return { list }
})