import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Transition as _Transition, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "setup-loader b"
}
const _hoisted_2 = {
  key: 1,
  class: "callendar-setup b"
}
const _hoisted_3 = { class: "page" }
const _hoisted_4 = {
  key: 2,
  class: "callendar-setup b"
}
const _hoisted_5 = { class: "page" }
const _hoisted_6 = {
  key: 3,
  class: "secondary-setup b"
}
const _hoisted_7 = { class: "page" }
const _hoisted_8 = {
  key: 4,
  class: "secondary-setup b"
}
const _hoisted_9 = { class: "page" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["setup b", { 'no-scroll': $setup.noScroll }])
  }, [
    ($setup.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode($setup["VLoader"])
        ]))
      : _createCommentVNode("v-if", true),
    ($setup.mode == $setup.SetupStage.CALENDAR)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_Transition, {
            onBeforeEnter: _cache[1] || (_cache[1] = ($event: any) => ($setup.noScroll = true)),
            onAfterLeave: _cache[2] || (_cache[2] = ($event: any) => ($setup.noScroll = false)),
            appear: ""
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                _createVNode($setup["SetupCalendar"], {
                  class: "setup",
                  onProgress: _cache[0] || (_cache[0] = ($event: any) => {$setup.mode = $setup.SetupStage.AVAILABILITY; $setup.completeSetup('CALENDAR')})
                })
              ])
            ]),
            _: 1 /* STABLE */
          })
        ]))
      : _createCommentVNode("v-if", true),
    ($setup.mode == $setup.SetupStage.AVAILABILITY)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_Transition, {
            onBeforeEnter: _cache[5] || (_cache[5] = ($event: any) => ($setup.noScroll = true)),
            onAfterLeave: _cache[6] || (_cache[6] = ($event: any) => ($setup.noScroll = false)),
            appear: ""
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_5, [
                _createVNode($setup["SetupAvailability"], {
                  class: "setup",
                  onProgress: _cache[3] || (_cache[3] = ($event: any) => {$setup.mode = $setup.SetupStage.READY; $setup.completeSetup('AVAILABILITY') }),
                  onBack: _cache[4] || (_cache[4] = ($event: any) => ($setup.mode = $setup.SetupStage.CALENDAR))
                })
              ])
            ]),
            _: 1 /* STABLE */
          })
        ]))
      : _createCommentVNode("v-if", true),
    ($setup.mode == $setup.SetupStage.READY)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createVNode(_Transition, {
            onBeforeEnter: _cache[9] || (_cache[9] = ($event: any) => ($setup.noScroll = true)),
            onAfterLeave: _cache[10] || (_cache[10] = ($event: any) => ($setup.noScroll = false)),
            appear: ""
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_7, [
                _createVNode($setup["SetupReady"], {
                  class: "setup",
                  onProgress: _cache[7] || (_cache[7] = ($event: any) => ($setup.mode = $setup.SetupStage.PROFILE)),
                  onBack: _cache[8] || (_cache[8] = ($event: any) => ($setup.mode = $setup.SetupStage.AVAILABILITY))
                })
              ])
            ]),
            _: 1 /* STABLE */
          })
        ]))
      : _createCommentVNode("v-if", true),
    ($setup.mode == $setup.SetupStage.PROFILE)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createVNode(_Transition, {
            onBeforeEnter: _cache[12] || (_cache[12] = ($event: any) => ($setup.noScroll = true)),
            onAfterLeave: _cache[13] || (_cache[13] = ($event: any) => ($setup.noScroll = false)),
            appear: ""
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_9, [
                _createVNode($setup["SetupProfile"], {
                  class: "setup",
                  onProgress: _cache[11] || (_cache[11] = ($event: any) => ($setup.routeTo('')))
                })
              ])
            ]),
            _: 1 /* STABLE */
          })
        ]))
      : _createCommentVNode("v-if", true)
  ], 2 /* CLASS */))
}