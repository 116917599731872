import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "loader-container flex-center"
}
const _hoisted_2 = {
  key: 0,
  class: "page"
}
const _hoisted_3 = {
  key: 0,
  class: "page"
}
const _hoisted_4 = {
  key: 0,
  class: "page"
}
const _hoisted_5 = {
  key: 0,
  class: "page"
}
const _hoisted_6 = {
  key: 0,
  class: "page"
}
const _hoisted_7 = {
  key: 0,
  class: "page"
}
const _hoisted_8 = {
  key: 0,
  class: "page"
}
const _hoisted_9 = {
  key: 0,
  class: "page"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    ($setup.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode($setup["Loader"])
        ]))
      : _createCommentVNode("v-if", true),
    _createElementVNode("div", {
      class: _normalizeClass(["withdraw-balance-container", {'no-scroll': $setup.noScroll}])
    }, [
      _createVNode(_Transition, {
        onBeforeEnter: _cache[0] || (_cache[0] = ($event: any) => ($setup.noScroll = true)),
        onAfterLeave: _cache[1] || (_cache[1] = ($event: any) => ($setup.noScroll = false)),
        appear: ""
      }, {
        default: _withCtx(() => [
          ($setup.stage === $setup.WidthdrawBalanceStage.TMP_PICK_0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode($setup["withdrawChoice"], { onMoveToStage: $setup.moveToStage })
              ]))
            : _createCommentVNode("v-if", true)
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_Transition, {
        onBeforeEnter: _cache[2] || (_cache[2] = ($event: any) => ($setup.noScroll = true)),
        onAfterLeave: _cache[3] || (_cache[3] = ($event: any) => ($setup.noScroll = false)),
        appear: ""
      }, {
        default: _withCtx(() => [
          ($setup.stage === $setup.WidthdrawBalanceStage.ACCT_SETUP_1)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode($setup["accountSetup"], { onMoveToStage: $setup.moveToStage })
              ]))
            : _createCommentVNode("v-if", true)
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_Transition, {
        onBeforeEnter: _cache[4] || (_cache[4] = ($event: any) => ($setup.noScroll = true)),
        onAfterLeave: _cache[5] || (_cache[5] = ($event: any) => ($setup.noScroll = false)),
        appear: ""
      }, {
        default: _withCtx(() => [
          ($setup.stage === $setup.WidthdrawBalanceStage.ACCT_CONFIRM_2)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode($setup["paymentConfirm"], { onMoveToStage: $setup.moveToStage })
              ]))
            : _createCommentVNode("v-if", true)
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_Transition, {
        onBeforeEnter: _cache[7] || (_cache[7] = ($event: any) => ($setup.noScroll = true)),
        onAfterLeave: _cache[8] || (_cache[8] = ($event: any) => ($setup.noScroll = false)),
        appear: ""
      }, {
        default: _withCtx(() => [
          ($setup.stage === $setup.WidthdrawBalanceStage.ACCT_WAITING_3)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createVNode($setup["PaymentInfoProcessing"], {
                  onPrevious: _cache[6] || (_cache[6] = ($event: any) => ($setup.routeTo('balance')))
                })
              ]))
            : _createCommentVNode("v-if", true)
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_Transition, {
        onBeforeEnter: _cache[9] || (_cache[9] = ($event: any) => ($setup.noScroll = true)),
        onAfterLeave: _cache[10] || (_cache[10] = ($event: any) => ($setup.noScroll = false)),
        appear: ""
      }, {
        default: _withCtx(() => [
          ($setup.stage === $setup.WidthdrawBalanceStage.ACCT_APPROVED_4)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createVNode($setup["PaymentSuccess"], { onMoveToStage: $setup.moveToStage })
              ]))
            : _createCommentVNode("v-if", true)
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_Transition, {
        onBeforeEnter: _cache[12] || (_cache[12] = ($event: any) => ($setup.noScroll = true)),
        onAfterLeave: _cache[13] || (_cache[13] = ($event: any) => ($setup.noScroll = false)),
        appear: ""
      }, {
        default: _withCtx(() => [
          ($setup.stage === $setup.WidthdrawBalanceStage.WITHDRAW_FORM_5)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createVNode($setup["WithdrawForm"], {
                  balance: $setup.balances.available.value,
                  disabled: $setup.disabled,
                  onAmountChange: $setup.onAmountChange,
                  onPrevious: _cache[11] || (_cache[11] = ($event: any) => ($setup.charges_enabled ? $setup.routeTo('balance') : $setup.stage = $setup.WidthdrawBalanceStage.ACCT_APPROVED_4)),
                  onSubmitPayout: $setup.onSubmitPayout
                }, null, 8 /* PROPS */, ["balance", "disabled"])
              ]))
            : _createCommentVNode("v-if", true)
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_Transition, {
        onBeforeEnter: _cache[14] || (_cache[14] = ($event: any) => ($setup.noScroll = true)),
        onAfterLeave: _cache[15] || (_cache[15] = ($event: any) => ($setup.noScroll = false)),
        appear: ""
      }, {
        default: _withCtx(() => [
          ($setup.stage === $setup.WidthdrawBalanceStage.WITHDRAW_PENDING_6)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _createVNode($setup["withdrawPending"], { amount: $setup.withdrawalAmount }, null, 8 /* PROPS */, ["amount"])
              ]))
            : _createCommentVNode("v-if", true)
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_Transition, {
        onBeforeEnter: _cache[16] || (_cache[16] = ($event: any) => ($setup.noScroll = true)),
        onAfterLeave: _cache[17] || (_cache[17] = ($event: any) => ($setup.noScroll = false)),
        appear: ""
      }, {
        default: _withCtx(() => [
          ($setup.stage === $setup.WidthdrawBalanceStage.WITHDRAW_SUBMITTED_7)
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                _createVNode($setup["withdrawSubmitted"], { amount: $setup.withdrawalAmount }, null, 8 /* PROPS */, ["amount"])
              ]))
            : _createCommentVNode("v-if", true)
        ]),
        _: 1 /* STABLE */
      })
    ], 2 /* CLASS */)
  ], 64 /* STABLE_FRAGMENT */))
}