import {Moment} from "moment";
import { ISOTimestamp } from '@busy-human/gearbox'
import { Weekday } from "@busy-human/opt-library";

export type ISOTimeChunk = {
    startTime: ISOTimestamp,
    endTime: ISOTimestamp
}

export type MomentTimeChunk = {
    startTime: Moment,
    endTime: Moment
}

export type DayWithMomentChunk = {
    day: string,
    timeChunk: {
        startTime: Moment,
        endTime: Moment
    }
}

export enum RequestStage {
    NONE = 0,
    DATE = 1,
    TIME = 2,
    INFO = 3,
    NOTE = 4,
    CONTACT = 5,
    CONFIRM = 6,
    CONGRATS = 7,
};

export interface MeetingInfo {
    id: string | undefined,
    meetingLength: number,
    year: number,
    month: number
}

export type TimeTuple = [hour: number, minute: number];

export type DayOfWeek = "monday"|"tuesday"|"wednesday"|"thursday"|"friday"|"saturday"|"sunday";

export interface DailyAvailability {
    startTime: TimeTuple,
    endTime: TimeTuple,
    availableDays: DayOfWeek[]
}

export const BusinessWeekDays = [Weekday.MON, Weekday.TUE, Weekday.WED, Weekday.THU, Weekday.FRI];

export function DefaultDailyAvailability() : DailyAvailability {
    return {
        startTime: [9,0],
        endTime: [17,0],
        availableDays: BusinessWeekDays
    };
}