import { ISOTimestamp } from "@busy-human/gearbox";
import Moment from "moment";
import { MomentTimeChunk, DayWithMomentChunk, DailyAvailability, DefaultDailyAvailability } from "./meeting-request-types";


export const DEFAULT_MINUTES = 30;
const STEP_UNIT = "minutes";

export interface AvailabilityPopulatorOptions {
    stepSize?: number; // minutes
}

export interface PopulateOptions {
    date: Moment.Moment;
    availability: DailyAvailability;
    events: MomentTimeChunk[];
}

const HEAT_DEATH = Moment({ year: 5000 });

/**
 * Given a set of boundaries and events, populate the available time slots
 */
export class AvailabilityPopulator {

    /** The date to populate for */
    moment: Moment.Moment;

    /** General daily availability for the sender */
    availability: DailyAvailability;

    /** The start and end boundaries to populate within */
    bounds: MomentTimeChunk;

    /** How many minutes to advance for each slot */
    stepSize: number;

    /** Right now (the actual current time) */
    now: Moment.Moment;

    /** Represents the start of a potential time slot */
    startPointer: Moment.Moment;

    /** Represents the end of the a potential time slot */
    endPointer: Moment.Moment;

    /** The index of the current event in the events array */
    eventIndex: number;

    /** The event we are currently comparing against */
    currentEvent: MomentTimeChunk | undefined;

    /** The list of events from a calendar that we don't want to conflict with */
    events: MomentTimeChunk[];

    /** The resulting time slots someone could schedule within */
    availableSlots: DayWithMomentChunk[];

    /**
     * Create a populator with a given step size
     * @param
     */
    constructor({stepSize = DEFAULT_MINUTES}: AvailabilityPopulatorOptions) {
        this.moment = Moment();
        this.stepSize = stepSize;
        this.availability = DefaultDailyAvailability();
        this.now = Moment();
        this.startPointer = this.now;
        this.endPointer = this.now;
        this.eventIndex = -1;
        this.availableSlots = [];
        this.bounds = {
            startTime: this.startPointer,
            endTime: this.endPointer,
        };
        this.events = [];
    }
    /**
     * Getter that returns the next end point to compare against
     * Which will be the closest of the start of the next event, or the end boundary
     */
    get nextEnd() {
       var currentEventEnd = this.currentEvent ? this.currentEvent.startTime : HEAT_DEATH;
       return this.bounds.endTime.isBefore(currentEventEnd) ? this.bounds.endTime : currentEventEnd;
    }
    /**
     * Sort events by their start times
     */
    sortEvents( events: MomentTimeChunk[]) {
        return events.sort((a,b) => {
            if(a.startTime.isSame(b.startTime, "minutes")) {
                return 0;
            } else if(a.startTime.isBefore(b.startTime, "minutes")) {
                return -1;
            } else {
                return 1;
            }
        });
    }
    /**
     * Returns whether or not MOVING the end pointer would be within bounds
     */
    withinBounds() {
        return this.startPointer.clone().add(this.stepSize, STEP_UNIT).isSameOrBefore(this.bounds.endTime, "minutes")
    }

    notPastSlot() {
        return this.now.isSameOrBefore(this.startPointer) || ENVIRONMENT === 'development'
    }
    /**
     * Process the availability and make sure the starting bound always falls on a correct step
     * @returns 
     */
    prepareBounds() {
        this.bounds = {
            startTime: this.moment.clone().hour(this.availability.startTime[0]).minute(this.availability.startTime[1]),
            endTime: this.moment.clone().hour(this.availability.endTime[0]).minute(this.availability.endTime[1])
        };

        var minutes = this.bounds.startTime.minutes();
        if(minutes % this.stepSize === 0) {
            return;
        } else {
            let stepsAbove = Math.ceil(minutes / this.stepSize);
            this.bounds.startTime.minutes(0).add(stepsAbove * this.stepSize);
        }
    }
    /**
     * Given a set of boundaries, and a set of events, return the evenly spaced time slots within
     * @param param0 
     * @returns 
     */
    run({date, availability, events}:PopulateOptions): DayWithMomentChunk[] {
        this.moment = date;
        this.availability = availability;
        this.prepareBounds();
        this.eventIndex = -1;
        this.currentEvent = undefined;
        this.availableSlots = [];
        this.startPointer = this.bounds.startTime.clone();
        this.endPointer = this.bounds.startTime.clone();    
        this.events = this.sortEvents(events);

        // console.log("populator.run; bounds: ", this.bounds);
        // console.log("populator.run; events: ", this.events);
        // let eventStr = "";
        // this.events.forEach(ev => eventStr += ev.startTime.format("MMM DD hh:mm a") + " - " + ev.endTime.format("hh:mm a") + ";\n");
        // console.log(eventStr);

        while( this.withinBounds() ) {
            this.conditionalNextEvent();
            this.moveEndPointer();

            if( this.hasEventOverlap() ) {
                this.movePointerToEndOfEvent();
                this.conditionalNextEvent();
            }
            if( ! this.hasEventOverlap() && this.withinBounds() && this.notPastSlot()) {
                this.createSlot();
            }

            this.startPointer = this.endPointer.clone();
        }
        // console.log(`Reached the end time ${this.bounds.endTime.format("MMM DD hh:mm a")}`);

        return this.availableSlots;
    }
    /**
     * Creates a time slot (DayWithMomentChunk) based on the current position of the start and end pointers
     */
    createSlot() {
        // console.log(`CREATE SLOT ${this.startPointer.format('MMM DD hh:mm a')} to ${this.endPointer.format('MMM DD hh:mm a')}`);
        this.availableSlots.push({
            day: Moment(this.startPointer).format("MMMM D, YYYY"),
            timeChunk: {
                startTime: this.startPointer.clone(),
                endTime: this.endPointer.clone(),                        
            }
        });
    }
    /**
     * Checks whether or not we are finished with the current event, and then if there is another
     * we move to it
     */
    conditionalNextEvent() {
        while(!this.currentEvent || this.startPointer.isSameOrAfter(this.currentEvent.endTime)) {
            if(this.eventIndex < this.events.length - 1) {
                this.eventIndex++;
                this.currentEvent = this.events[this.eventIndex];
            } else {
                this.currentEvent = undefined;
                break;
            }        
            // if(this.currentEvent) {
            //     console.log("");
            //     console.log(`CURRENT EVENT ${this.currentEvent.startTime.format('MMM DD hh:mm a')} to ${this.currentEvent.endTime.format('MMM DD hh:mm a')}`)
            // }   
        }
    }
    /**
     * Move the pointers using the step size until neither pointer overlaps the event
     */
    movePointerToEndOfEvent() {
        while(this.hasEventOverlap()) {
            // console.log(`Pointer set ${this.startPointer.format('MMM DD hh:mm a')} to ${this.endPointer.format('MMM DD hh:mm a')} has overlap, moving forward`)
            // console.log("");
            this.startPointer = this.endPointer.clone();
            this.moveEndPointer();
        }
    }
    /**
     * Indicates whether or not the pointers are overlapping the currentEvent
     * @returns 
     */
    hasEventOverlap() {
        if(!this.currentEvent) {
            return false;
        } else {
            let bothBefore = this.startPointer.isSameOrBefore(this.currentEvent.startTime) 
                && this.endPointer.isSameOrBefore(this.currentEvent.startTime);
            let bothAfter = !bothBefore && this.startPointer.isSameOrAfter(this.currentEvent.endTime) 
                && this.endPointer.isSameOrAfter(this.currentEvent.endTime);

            // console.log(`Current Event ${this.currentEvent.startTime.format('MMM DD hh:mm a')} to ${this.currentEvent.endTime.format('MMM DD hh:mm a')} bothBefore: ${bothBefore}; bothAfter: ${bothAfter}`)

            return !bothBefore && !bothAfter;
        }
    }
    /** Move the end pointer according to the step size (no conditions) */
    moveEndPointer() {
        this.endPointer.add(this.stepSize, STEP_UNIT);   
    }
}