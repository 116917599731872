import { SubsetEntityInterface } from "@busy-human/gearbox";
import { Calendars, DataInstance, Meetings, Userspaces } from "@busy-human/opt-library";
import { onSnapshot, query, Unsubscribe, where } from "firebase/firestore";
import { uniqBy } from "lodash";
import { defineStore } from "pinia";
import { computed, ref, watch } from "vue";
import { useUserspace } from './userspace';

export const useCalendars = defineStore("calendars", () => {
    const calendars = ref<DataInstance<Calendars.OptCalendar>[]>([]);
    const listenerCleanup = ref<Unsubscribe | null>(null);

    async function setup(uid: string) {
        // Preemptive cleanup
        if(listenerCleanup.value) listenerCleanup.value();

        const calendarsRef = Userspaces.Collection.subCollectionEntity(uid, 'calendars')
            .all() as SubsetEntityInterface<Calendars.OptCalendar>;

        calendars.value = (await calendarsRef.fetch()).map(doc => doc.data());
        
        listenerCleanup.value = onSnapshot(calendarsRef.ref(), snap => {
            const data: DataInstance<Calendars.OptCalendar>[] = 
                snap.docs.map(docSnap => 
                    ({... docSnap.data() as Calendars.OptCalendar, 
                    $id: docSnap.id
                }));
            calendars.value = data;
        });
    }

    function cleanup() {
        if(listenerCleanup.value) listenerCleanup.value();

        calendars.value = [];
    }

    const userspace = useUserspace();

    watch(() => userspace.uid, async uid => {
        if(uid) await setup(uid);
        else cleanup();
    }, { immediate: true });

    return { calendars }
})