import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "page"
}
const _hoisted_2 = {
  key: 0,
  class: "page"
}
const _hoisted_3 = {
  key: 0,
  class: "page"
}
const _hoisted_4 = {
  key: 0,
  class: "page"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["referral-container", {'no-scroll': $setup.noScroll}])
  }, [
    _createVNode(_Transition, {
      onBeforeEnter: _cache[2] || (_cache[2] = ($event: any) => ($setup.noScroll = true)),
      onAfterLeave: _cache[3] || (_cache[3] = ($event: any) => ($setup.noScroll = false)),
      appear: ""
    }, {
      default: _withCtx(() => [
        ($setup.stage === $setup.ReferralStage.START)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createVNode($setup["ReferralStart"], {
                onProgress: _cache[0] || (_cache[0] = ($event: any) => ($setup.stage = $setup.ReferralStage.EMAIL)),
                onPrevious: _cache[1] || (_cache[1] = ($event: any) => ($setup.goTo('/')))
              })
            ]))
          : _createCommentVNode("v-if", true)
      ]),
      _: 1 /* STABLE */
    }),
    _createVNode(_Transition, {
      onBeforeEnter: _cache[7] || (_cache[7] = ($event: any) => ($setup.noScroll = true)),
      onAfterLeave: _cache[8] || (_cache[8] = ($event: any) => ($setup.noScroll = false)),
      appear: ""
    }, {
      default: _withCtx(() => [
        ($setup.stage === $setup.ReferralStage.EMAIL)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode($setup["ReferralEmail"], {
                referral: $setup.referral,
                "onUpdate:referral": _cache[4] || (_cache[4] = ($event: any) => (($setup.referral) = $event)),
                onProgress: _cache[5] || (_cache[5] = ($event: any) => ($setup.stage = $setup.ReferralStage.NOTE)),
                onPrevious: _cache[6] || (_cache[6] = ($event: any) => ($setup.stage = $setup.ReferralStage.START))
              }, null, 8 /* PROPS */, ["referral"])
            ]))
          : _createCommentVNode("v-if", true)
      ]),
      _: 1 /* STABLE */
    }),
    _createVNode(_Transition, {
      onBeforeEnter: _cache[12] || (_cache[12] = ($event: any) => ($setup.noScroll = true)),
      onAfterLeave: _cache[13] || (_cache[13] = ($event: any) => ($setup.noScroll = false)),
      appear: ""
    }, {
      default: _withCtx(() => [
        ($setup.stage === $setup.ReferralStage.NOTE)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode($setup["ReferralNote"], {
                referral: $setup.referral,
                "onUpdate:referral": _cache[9] || (_cache[9] = ($event: any) => (($setup.referral) = $event)),
                onCreateInstance: $setup.createLink,
                onProgress: _cache[10] || (_cache[10] = ($event: any) => ($setup.stage = $setup.ReferralStage.SEND)),
                onPrevious: _cache[11] || (_cache[11] = ($event: any) => ($setup.stage = $setup.ReferralStage.EMAIL))
              }, null, 8 /* PROPS */, ["referral"])
            ]))
          : _createCommentVNode("v-if", true)
      ]),
      _: 1 /* STABLE */
    }),
    _createVNode(_Transition, {
      onBeforeEnter: _cache[16] || (_cache[16] = ($event: any) => ($setup.noScroll = true)),
      onAfterLeave: _cache[17] || (_cache[17] = ($event: any) => ($setup.noScroll = false)),
      appear: ""
    }, {
      default: _withCtx(() => [
        ($setup.stage === $setup.ReferralStage.SEND)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createVNode($setup["ReferralSend"], {
                instance: $setup.referralInstance,
                "onUpdate:instance": _cache[14] || (_cache[14] = ($event: any) => (($setup.referralInstance) = $event)),
                error: $setup.error,
                onProgress: _cache[15] || (_cache[15] = ($event: any) => ($setup.goTo('/')))
              }, null, 8 /* PROPS */, ["instance", "error"])
            ]))
          : _createCommentVNode("v-if", true)
      ]),
      _: 1 /* STABLE */
    })
  ], 2 /* CLASS */))
}