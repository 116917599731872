import { db } from "@/util/firebase"
import { ISOTimestamp } from "@busy-human/gearbox";
import { sendEmailVerification } from "firebase/auth";
import { collection, getDoc, doc, getDocs, getDocsFromCache, getDocsFromServer, addDoc, deleteDoc, onSnapshot, updateDoc } from "firebase/firestore"
import { times } from "lodash";
import { MeetingInfo, MomentTimeChunk, ISOTimeChunk } from "./components/meeting-request-2/meeting-request-types";
import getNextLoadingTip from "./util/loading-tips";
import moment from "moment";
import { convertMomentToISOTimestamp } from "./components/meeting-request-2/schedule-util";

class handleCalendarEvents{
    Months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
    daysOfWeek = ["monday","tuesday","wednesday","thursday","friday","saturday","sunday"]
    uid: string | undefined;
    events: ISOTimeChunk[]
    defaultMeetingLength: number
    month: string
    availabilityStartTime: number | undefined
    availabilityEndTime: number | undefined
    availableDays: string[] | undefined
    daysNotAvailable: string[] | undefined
    busyDays: moment.Moment[]
    availableTimes: ISOTimeChunk[]
    availabilityStartHour: number | undefined
    availabilityStartMinute: number | undefined
    availabilityEndHour: number | undefined
    availabilityEndMinute: number | undefined
    lastEvent: moment.Moment | undefined
    startPointer?: moment.Moment
    endPointer?: moment.Moment
    bufferTime: number;


    constructor(data:MeetingInfo, events: ISOTimeChunk[]){
        this.uid = data.id;
        this.events = events;
        this.defaultMeetingLength  = data.meetingLength
        this.month = this.Months[data.month] 
        this.daysNotAvailable = []
        this.availableTimes = []
        this.busyDays = []
        this.bufferTime = 0;
    }
    
    // Please use gearbox so you have proper type safety
    async setData(){
        // console.log("EVENTS",this.events)

        const userRef = doc(db, "Userspaces", this.uid)
        const docSnap =  await getDoc(userRef)
        if(docSnap.exists()){
            const tmpData = docSnap.data();
            // console.log("DATA:",tmpData)
            const timesAvailable = Array.isArray(tmpData.timesAvailable) ? tmpData.timesAvailable[0] : tmpData.timesAvailable;
            // console.log("TIMES:", timesAvailable)
            this.availabilityStartTime = timesAvailable.startTime
            this.availabilityEndTime = timesAvailable.endTime
            this.availableDays = [...timesAvailable.days]
            if((this.availabilityStartTime - Math.floor(this.availabilityStartTime)) !==0 ){
                //TODO:need to convert minutes
                this.availabilityStartHour = this.availabilityStartTime;
                this.availabilityStartMinute = 0
            }else{
                this.availabilityStartHour = this.availabilityStartTime;
                this.availabilityStartMinute = 0
            }
            if((this.availabilityEndTime - Math.floor(this.availabilityEndTime)) !==0 ){
                //TODO:need to convert minutes
                this.availabilityEndHour = this.availabilityEndTime;
                this.availabilityEndMinute = 0
            }else{
                this.availabilityEndHour = this.availabilityEndTime;
                this.availabilityEndMinute = 0
            }
        }else{
            console.log("Document doesn't exist.")
        }
        // console.log("EVENTS PASSED:", this.events)
        // for(let i = 0; i < this.events.length; i++){
        //     console.log(moment(this.events[i].startTime))
        //     console.log(moment(this.events[i].startTime).format("h:mma"),moment(this.events[i].endTime).format("h:mma"))
        // }
        this.getUnavailableDays()
    }
    
    //gets all the days that are set to unavailable by user
    getUnavailableDays(){
        // console.log("Get unavailable days called")
        for(let i = 0; i < this.daysOfWeek.length; i++){
            if(!this.availableDays.includes(this.daysOfWeek[i])){
                this.daysNotAvailable.push(this.daysOfWeek[i])
            }
        }
        // this.sortEvents()
        this.loopThroughEvents()
    }

    /**
     * Loops through all the passed events
     * and makes an array of events that happen on the same day
     * and passes that array to movePointer to get time chunks of available times
     */
    loopThroughEvents(){
        // console.log("loopThroughEvents called")
        for(let i = 0; i < this.events.length; i++){
            /** Calendar events */
            let events: ISOTimeChunk[] = []
            this.startPointer = moment(this.events[i].startTime)
            this.endPointer = moment(this.events[i].endTime)
            this.startPointer.set({h:this.availabilityStartHour,m:this.availabilityStartMinute})
            this.endPointer.set({h:this.availabilityEndHour,m:this.availabilityEndMinute})
            let day = this.startPointer.date()
            events.push(this.events[i])

            for(let j = i+1; j < this.events.length; j++){
                let tmpDay = moment(this.events[j].startTime).date()
                if(day == tmpDay){
                    events.push(this.events[j])
                }else{
                    break;
                }
            }

            // console.log("loopThroughEvents events:", events);
            this.movePointers(events)
        }
    }

    /**
     * Moves pointers and gets time chunks of available times
     */
    movePointers(event:ISOTimeChunk[]){
        // console.log("MOVE POINTERS CALLED", event)
        let startTimes = []
        let endTimes = []
        let availableTimesForDay: MomentTimeChunk[] = []

        if(!this.startPointer || !this.endPointer){
            throw new Error("Date pointers unset");
        }

        for(let i = 0; i < event.length; i++){
            startTimes.push(moment(event[i].startTime))
            endTimes.push(moment(event[i].endTime))
            // console.log("Start:", startTimes[i].format("h:mma"))
            // console.log("End:", endTimes[i].format('h:mma'))
        }

        for(let i = 0; i < event.length; i++){
            let bufferedStart: moment.Moment = startTimes[i].subtract(this.bufferTime,'minutes')
            let bufferedEnd: moment.Moment = endTimes[i].add(this.bufferTime,'minutes')
            let endOfAvailability = moment(this.startPointer).set({h:this.availabilityEndHour,m:this.availabilityEndMinute})
            // console.log("Pointer",moment(this.startPointer).format("h:mma"),moment(this.endPointer).format("h:mma"))
            // console.log("BUFFSTART",bufferedStart.format("h:mma"),"BUFFEND",bufferedEnd.format("h:mma"),"END",endOfAvailability.format("h:mma"))


            //If the start time of event is after startPointer
            //add more statements?
            if(this.startPointer.isBefore(bufferedStart)){
                let difference = bufferedStart.diff(this.startPointer,"minutes")
                // console.log("DIFFERENCE",difference)
                if(difference > this.defaultMeetingLength){
                    //move end pointer to start of event
                    this.endPointer = startTimes[i]
                    
                    //creates a chunk of available time
                    let timeAvailable: MomentTimeChunk = {
                        startTime: this.startPointer,
                        endTime: this.endPointer
                    }
                    availableTimesForDay.push(timeAvailable)
                    //yup!
                    for(let i = 0; i < availableTimesForDay.length; i++){
                        // console.log("HERE:",availableTimesForDay[i].start.format("h:mma"), availableTimesForDay[i].end.format("h:mma"))
                    }

                    //checkts to see if there are more events if so update pointers
                    if(event[i+1]){
                        this.startPointer = bufferedEnd
                        this.endPointer = endOfAvailability

                    //if no more events check if there are any more time slots
                    }else{
                        this.startPointer = bufferedEnd
                        this.endPointer = endOfAvailability
                        let difference = this.endPointer.diff(this.startPointer,"minutes")
                        // console.log("startPointer",this.startPointer.format("h:mma"))
                        // console.log("endPointer",this.endPointer.format("h:mma"))
                        if(difference > this.defaultMeetingLength){
                            let timeAvailable : MomentTimeChunk = {
                                startTime: this.startPointer,
                                endTime: this.endPointer
                            }
                            availableTimesForDay.push(timeAvailable)
                            //yup!
                            // for(let i = 0; i < availableTimesForDay.length; i++){
                            //     console.log("FROM CALENDAREVENTS2: times for day:",availableTimesForDay[i].startTime.format("h:mma"), availableTimesForDay[i].endTime.format("h:mma"))
                            // }
                            // console.log("--");
                        }
                    }



                }else{
                    this.startPointer = bufferedEnd
                    this.endPointer = endOfAvailability
                    
                    if(event[i+1]){
                        let nextBufferedStart = startTimes[i+1].subtract(this.bufferTime,'minutes')
                        let nextBufferedEnd = endTimes[i+1].add(this.bufferTime,'minutes')
                        this.startPointer = bufferedEnd;
                        let difference = nextBufferedStart.diff(this.startPointer,"minutes")
                        if(difference > this.defaultMeetingLength){
                            this.endPointer = nextBufferedStart
                            let timeAvailable: MomentTimeChunk = {
                                startTime: this.startPointer,
                                endTime: this.endPointer
                            }
                            availableTimesForDay.push(timeAvailable)
                           
                        }
                        this.startPointer = nextBufferedEnd
                        this.endPointer = endOfAvailability
    
                    }else{
                        this.startPointer = bufferedEnd
                        this.endPointer = endOfAvailability
                        let difference = this.endPointer.diff(this.startPointer,"minutes")
                        if(difference > this.defaultMeetingLength){
                            let timeAvailable: MomentTimeChunk = {
                                startTime: this.startPointer,
                                endTime: this.endPointer
                            }
                            availableTimesForDay.push(timeAvailable)
                        }
                    }
                }
            }else{
                this.startPointer = bufferedEnd
                if(event[i+1]){
                    // console.log("HIT>??")
                    let bufferedStartNext = startTimes[i+1].subtract(this.bufferTime,'minutes')
                    let bufferedEndNext = endTimes[i+1].add(this.bufferTime,'minutes')
                    // console.log("next buffs", bufferedStartNext.format("h:mma"),bufferedEndNext.format("h:mma"))
                    let difference = bufferedStartNext.diff(this.startPointer,"minutes")
                    if(difference > this.defaultMeetingLength){
                        // console.log("NEW TIME?")
                        let timeAvailable: MomentTimeChunk = {
                            startTime: this.startPointer,
                            endTime: bufferedStartNext
                        }
                        availableTimesForDay.push(timeAvailable)
                        this.startPointer = bufferedStartNext
                        this.endPointer = endOfAvailability
                    }

                }else{
                    //check for bounds?
                    // console.log("HIT THE ELSE")
                    if(moment(this.startPointer).isBefore(this.endPointer)){
                        let difference = this.endPointer.diff(this.startPointer)
                        if(difference > this.defaultMeetingLength){
                            let timeAvailable : MomentTimeChunk = {
                                startTime: this.startPointer,
                                endTime: this.endPointer
                            }
                            availableTimesForDay.push(timeAvailable)
                        }
                    }
                }
            }
            this.lastEvent = startTimes[i]
        }
        if(availableTimesForDay.length < 1){
            if(this.lastEvent) {
                // There were no available times in the day, so the entire day is blocked
                this.busyDays.push(this.lastEvent)
            }
        }
        else{
            // Push all the available times for the day into the master list
            for(let i = 0; i < availableTimesForDay.length; i++){
                let asIso = convertMomentToISOTimestamp(availableTimesForDay[i]);
                this.availableTimes.push(asIso);
                // console.log("TIMES AVAILABLE FOR DAY:", availableTimesForDay[i].start.format("h:mma"), availableTimesForDay[i].end.format("h:mma"))
            }
        }
        // for(let i = 0; i < this.availableTimes.length; i++){
        //     console.log(this.availableTimes[i])
        //     console.log(this.availableTimes[i].start.format("h:mma"), (this.availableTimes[i].end.format("h:mma")))
        // }
      
    }

    getTimeChunks(){
        return this.availableTimes
    }
    returnUnavailableDays(){
        return this.daysNotAvailable
    }
    returnBusyDays(){
        // console.log("BUSY DAYS",this.busyDays)
        return this.busyDays
    }
    returnBufferedStart(){
        let startTime = moment().set({h:this.availabilityStartHour,m:this.availabilityStartMinute}).add(this.bufferTime,'minutes')
        return startTime
    }
    returnBufferedEnd(){
        // let endTime = moment().set({h:this.availabilityEndHour,m:this.availabilityEndMinute}).subtract(this.defaultMeetingLength,'minutes') //TODO:REPLACE WITH PASS VAR
        let endTime = moment().set({h:this.availabilityEndHour,m:this.availabilityEndMinute}) //TODO:REPLACE WITH PASS VAR
        return endTime
    }
    returnMeetingLength(){
        return this.defaultMeetingLength
    }
}



export default handleCalendarEvents